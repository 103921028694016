import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import service from '../../services/service';
import userLogo from '../../assets/user.svg';
import mailLogo from '../../assets/mail.svg';
import mobileLogo from '../../assets/mobile.svg';
import lockLogo from '../../assets/lock.svg';

import _ from 'lodash';
import './signupForm.less';
import Captcha from '../../shared/captcha';

const SignupForm = ({ onSwitch, closemodel }) => {
  const displayName = 'cmp-signup';
  const [name, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [errRes, setErrorResponse] = useState('');
  const [recaptchatoken, setReCaptchaToken] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [strength, setStrength] = useState(null);

  // const [phone, setPhone] = useState();

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrors({});
    setErrorResponse('');
    try {
      const response = await service.register(name, email, confirmPassword, recaptchatoken);
      if (response.status === 201) {
        toast.success('Registration Successful', { zindex: 200000, position: 'bottom-right' });
        closemodel();
      }
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (!_.isEmpty(errorMessage)) {
        toast.error(errorMessage, { zindex: 200000, position: 'bottom-right' });
        if (errorMessage.toLowerCase().includes('email')) {
          setErrors({ email: ' ' });
        } else {
          setErrors({ name: ' ', email: ' ', password: ' ' });
        }
      } else toast.error('Internal Server Error', { zindex: 200000, position: 'bottom-right' });
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // setStrength(evaluatePasswordStrength(e.target.value)); // Password Stength Indicator
    validatePasswords(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = useCallback((e) => {
    setConfirmPassword(e.target.value);
    validatePasswords(password, e.target.value);
  }, [password]);

  const validatePasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: 'Passwords do not match',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: '',
      }));
    }
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validate = (name, email, password, confirmPassword) => {
    const newErrors = {};
    if (_.isEmpty(name)) newErrors.name = 'Name cannot be empty';
    if (_.isEmpty(email)) newErrors.email = 'Invalid email';
    if (!emailRegex.test(email)) newErrors.email = 'Invalid email';
    if (_.isEmpty(password)) newErrors.password = 'Password cannot be empty';
    if (_.isEmpty(confirmPassword)) newErrors.confirmPassword = 'Confirm Password cannot be empty';
    return newErrors;
  };

  const reCaptchaToken = (event) => {
    setReCaptchaToken(event);
    const newErrors = validate(name, email, password, confirmPassword);
    if (!_.isEmpty(newErrors)) {
      setErrors(newErrors);
    } else if (!event) {
      console.warn(event);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const evaluatePasswordStrength = (password) => {
    let strength = 'Weak';
    const lengthCriteria = password.length >= 6;
    const upperCaseCriteria = /[A-Z]/.test(password);
    const lowerCaseCriteria = /[a-z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[^A-Za-z0-9]/.test(password);
    const commonPatterns = /^(123456|password|qwerty|abc123|111111|letmein|welcome|admin|login|123123)$/i;

    if (commonPatterns.test(password)) {
      strength = 'Very Weak';
    } else if (lengthCriteria && upperCaseCriteria && lowerCaseCriteria && numberCriteria && specialCharCriteria) {
      strength = 'Strong';
    } else if (lengthCriteria && (upperCaseCriteria || lowerCaseCriteria) && numberCriteria) {
      strength = 'Medium';
    }

    return strength;
  };


  return (
    <div className={`${displayName}`}>
      <header className={`${displayName}__header`}>Create your account by filling out the information below.</header>
      <form className={`${displayName}__form`} onSubmit={handleRegister}>
        <div
          className={`${displayName}__field ${errors.name ? `${displayName}_field--shake ${displayName}__field--error` : ''}`}
        >
          <label className={`${displayName}__label`}>Full Name</label>
          <div className={`${displayName}__input-area`}>
            <input
              className={`${displayName}__input`}
              type='text'
              value={name}
              onChange={(e) => setUsername(e.target.value)}
              placeholder='Enter Full Name'
            />
            <img className={`${displayName}__icon`} src={userLogo} alt='user icon' />
            {errors.name && <span className={`${displayName}__error-txt`}>{errors.name}</span>}
          </div>
        </div>
        <div
          className={`${displayName}__field ${errors.email ? `${displayName}__field--shake ${displayName}__field--error` : ''}`}
        >
          <label className={`${displayName}__label`}>Email</label>
          <div className={`${displayName}__input-area`}>
            <input
              className={`${displayName}__input`}
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Enter Email'
              autoComplete="siginup-email"
            />
            <img className={`${displayName}__icon`} src={mailLogo} alt='user icon' />
            {errors.email && <span className={`${displayName}__error-txt`}>{errors.email}</span>}
          </div>
        </div>
        {/* <div className={`${displayName}__field ${errors.phone ? `${displayName}__field--shake ${displayName}__field--error` : ''}`}>
          <label className={`${displayName}__label`}>Mobile No.</label>
          <div className={`${displayName}__input-area`}>
            <input
              className={`${displayName}__input`}
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter Mobile No."
            />
            <img className={`${displayName}__icon`} src={mobileLogo} alt="user icon" />
            {errors.phone && <span className={`${displayName}__error-txt`}>{errors.phone}</span>}
          </div>
        </div> */}

        <div
          className={`${displayName}__field ${errors.password ? `${displayName}__field--shake ${displayName}__field--error` : ''}`}
        >
          {/* <div className={`${displayName}__pwdstrength`}>
            {strength && <span>Strength: <strong>{strength}</strong></span>}
          </div> */}
          <label className={`${displayName}__label`}>Password</label>
          <div className={`${displayName}__input-area`}>
            <input
              className={`${displayName}__input`}
              type='password'
              value={password}
              onChange={handlePasswordChange}
              placeholder='Enter Password'
              autoComplete="siginup-password"
            />
            <img className={`${displayName}__icon`} src={lockLogo} alt='user icon' />
            {errors.email && <span className={`${displayName}__error-txt`}>{errors.password}</span>}
          </div>
        </div>
        <div
          className={`${displayName}__field ${errors.confirmPassword ? `${displayName}__field--shake ${displayName}__field--error` : ''}`}
        >
          <label className={`${displayName}__label`}>Confirm Password</label>
          <div className={`${displayName}__input-area`}>
            <input
              className={`${displayName}__input`}
              type='password'
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder='Confirm Password'
              autoComplete="siginup-password"
            />
            <img className={`${displayName}__icon`} src={lockLogo} alt='user icon' />
            {errors.confirmPassword && <span className={`${displayName}__error-txt`}>{errors.confirmPassword}</span>}
          </div>
          {errRes && <span className={`${displayName}__error-txt`}>{errRes}</span>}
        </div>
        <div className={`${displayName}__captcha`}>
          <Captcha retoken={reCaptchaToken}></Captcha>
        </div>
        <div className={`${displayName}__submit-btn`}>
          <input className={`${displayName}__submit`} disabled={isButtonDisabled} type='submit' value='Sign up' />
        </div>
      </form>
      <p className={`${displayName}__login-text`}>
        Already have an account?{' '}
        <a className={`${displayName}__link`} onClick={onSwitch} href='#'>
          Login
        </a>
      </p>
    </div>
  );
};

export default SignupForm;

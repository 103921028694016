import React from 'react';
import footNoteData from '../../../../../shared/json-config/footNoteData.json';

const FootNotesComponent = ({ name, tableFor, selectedYears, isYearly }) => {

  const renderFootnotes = (name, tableFor, selectedYearArray, isYearly) => {
    const items = footNoteData.filter(item =>
      item.name === name &&
      item.tableFor === tableFor &&
      selectedYearArray.includes(item.year) &&
      item.isYearly === isYearly
    );

    if (items.length > 0) {
      return (
        <ul style={{ margin: "0" }}>
          <li>The final year of the REIT's data includes debt figures subsequent to the end of quarter/year.</li>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {item.data.length > 0 && item.data.map((footnote, idx) => (
                <li key={idx}>{footnote}</li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      );
    }
    return null;
  };

  const hasFootnotes = footNoteData.some(item =>
    item.name === name &&
    selectedYears.includes(item.year) &&
    item.isYearly === isYearly &&
    item.tableFor === tableFor
  );

  return (
    hasFootnotes && (
      <div style={{ fontSize: "12px", display: "flex", padding: "8px" }}>
        <span>Note:</span>
        <span>{renderFootnotes(name, tableFor, selectedYears, isYearly)}</span>
      </div>
    )
  );
};

export default FootNotesComponent;

import React, { useState } from 'react';
import { processData } from './processData';

import "./index.less"

const ReitBanner = (props) => {

  const displayName = 'cmp-reit-banner';

  const [hoverLocation, setHoverLocation] = useState(false);
  const [hoverAssets, setHoverAssets] = useState(false);
  const [hoverSponsors, setHoverSponsors] = useState(false);

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  const {
    _reitData,
    _uniqueLocations,
    _nsedata,
    _bsedata,
    _reitYearData,
    _sponsors,
    _desc,
    _assectclass
  } = processData(props.props);

  const displayLocations = hoverLocation || _uniqueLocations.length <= 4
    ? _uniqueLocations.slice(4,).join(',\n')
    : _uniqueLocations.slice(0, 4).join(', ') + '...';

  const displaySponsors = hoverSponsors || _sponsors.length <= 2
    ? _sponsors.join(',\n')
    : _sponsors.slice(0, 2).join(', ') + '...';

  const displayAssetClass = hoverAssets || _assectclass.length < 2
    ? _assectclass.join(',\n')
    : _assectclass.slice(0, 1).join(', ') + '...';
  return (
    <div className={`${displayName}`}>
      <div className={`${displayName}_banner`}>
        <div className={`${displayName}_banner__header`}>
          <table className={`${displayName}_banner__header_tbl`}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div className={`${displayName}_banner__header_tbl_col1`}>
                    <span className={`${displayName}_banner__header_tbl_col1--name`}>
                      {_reitData.name}
                    </span>
                    <span className={`${displayName}_banner__header_tbl_col1--locations`}>
                      {_uniqueLocations.slice(0, 4).join(', ')}
                      {_uniqueLocations.length > 4 &&
                        <>
                          &nbsp;&&nbsp;
                          <span
                            className={`${displayName}_banner__header_tbl_col1--locations--more`}
                            onMouseEnter={() => setHoverLocation(true)}
                            onMouseLeave={() => setHoverLocation(false)}
                            title={displayLocations}
                            style={{ cursor: 'pointer' }}
                          >View More
                          </span>
                        </>
                      }
                    </span>
                  </div>
                </td>
                <td className={`${displayName}_banner__header_tbl_caaplScore`}>
                  {/* <span>CAAPL Score</span>
                    <p>8/10</p> */}
                </td>

                <td className={`${displayName}_banner__header_tbl_bse`}>
                  <div className={`${displayName}_banner__header_tbl_bse_cont`}>
                    <div className={`${displayName}_banner__header_tbl_bse_cont-title`}>
                      <span className={`${displayName}_banner__header_tbl_bse_cont-title--name`}>
                        NSE
                      </span>
                      <span className={`${displayName}_banner__header_tbl_bse_cont-title--live`}>
                        (Prev Close)
                      </span>
                    </div>
                    <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle`}>
                      <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle-exchange`}>
                        <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle-exchange-value`}>
                          {_nsedata.closingPrice.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </div >
                        <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle-exchange-ticker`}>
                          <span style={{ color: _nsedata.priceChange.value >= 0 ? '#5ad05a' : _nsedata.priceChange.value < 0 ? 'red' : 'black', marginLeft: "2px" }}>
                            ({_nsedata.priceChange.value})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td className={`${displayName}_banner__header_tbl_bse`}>
                  <div className={`${displayName}_banner__header_tbl_bse_cont`}>
                    <div className={`${displayName}_banner__header_tbl_bse_cont-title`}>
                      <span className={`${displayName}_banner__header_tbl_bse_cont-title--name`}>
                        BSE
                      </span>
                      <span className={`${displayName}_banner__header_tbl_bse_cont-title--live`}>
                        (Prev Close)
                      </span>
                    </div>
                    <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle`}>
                      <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle-exchange`}>
                        <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle-exchange-value`}>
                          {_bsedata.closingPrice.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </div >
                        <div className={`${displayName}_banner__header_tbl_bse_cont-subtitle-exchange-ticker`}>
                          <span style={{ color: _bsedata.priceChange.value >= 0 ? '#5ad05a' : _bsedata.priceChange.value < 0 ? 'red' : 'black', marginLeft: "2px" }}>
                            ({_bsedata.priceChange.value})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td className={`${displayName}_banner__header_tbl_primary`}>
                  <div className={`${displayName}_banner__header_tbl_primary-container`}>
                    <div className={`${displayName}_banner__header_tbl_primary-container-assets`}>
                      <span className={`${displayName}_banner__header_tbl_primary-container-assets--link`}>
                        Primary Asset Class :
                        <span className={`${displayName}_banner__header_tbl_primary-container-assets--link--hover`} //style={{ fontWeight: "600" }}
                          onMouseEnter={() => setHoverAssets(true)}
                          onMouseLeave={() => setHoverAssets(false)}
                          title={displayAssetClass}>
                          {_assectclass.map(_d => _d).slice(0, 1).join(', ')}</span>...
                      </span>
                    </div>
                    <div className={`${displayName}_banner__header_tbl_primary-container-date`}>
                      <span className={`${displayName}_banner__header_tbl_primary-container-date--name`}>
                        Listing Date :&nbsp;
                        <span className={`${displayName}_banner__header_tbl_primary-container-date--time`} >
                          {new Date(_reitData.about[0].registeredSebiDate.value).toLocaleDateString('en-US', options)}
                        </span>
                      </span>
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={`${displayName}_banner__body`}>
          <table className={`${displayName}_banner__body_tbl`}>
            <tbody>
              <tr>
                <td colSpan={6}>
                  <div className={`${displayName}_banner__body_tbl-col1hr`}>
                    <div className={`${displayName}_banner__body_tbl-col1hr--hr`}></div></div>
                </td>
              </tr>

              {/* <tr>
                  <td>
                    <span className={`${displayName}_banner__body_tbl-title`}>TOTAL PORTFOLIO</span>
                  </td>
                  <td>
                    <span className={`${displayName}_banner__body_tbl-title`}>COMPLETE AREA</span>
                  </td>
                  <td>
                    <span className={`${displayName}_banner__body_tbl-title`}>MARKET CAPITALIZATION </span>
                  </td>
                  <td>
                    <span className={`${displayName}_banner__body_tbl-title`}>GROSS ASSET VALUE </span>
                  </td>
                  {_reitYearData.financialData.distributionYield.value &&
                    <td>
                      <span className={`${displayName}_banner__body_tbl-title`}>DISTRIBUTION YIELD</span>
                    </td>
                  }
                  <td className='width15'>
                    <span className={`${displayName}_banner__body_tbl-title`}>SPONSORS</span>
                  </td>
                </tr> */}

              <tr>
                <td>
                  <div className={`${displayName}_banner__body_flex`}>
                    <span className={`${displayName}_banner__body_tbl-title`}>TOTAL PORTFOLIO</span>
                    <span className={`${displayName}_banner__body_tbl-value`}>
                      {typeof _reitYearData.portfolioSummary.portfolioSize.value === 'string' && _reitYearData.portfolioSummary.portfolioSize.value.includes('msf')
                        ? _reitYearData.portfolioSummary.portfolioSize.value
                        : parseFloat(_reitYearData.portfolioSummary.portfolioSize.value).toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + " msf"
                      }
                    </span></div>
                </td>
                <td>
                  <div className={`${displayName}_banner__body_flex`}>
                    <span className={`${displayName}_banner__body_tbl-title`}>COMPLETE AREA</span>
                    <span className={`${displayName}_banner__body_tbl-value`}>
                      {/* {_reitYearData.portfolioSummary.completedArea.value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} msf */}
                      {typeof _reitYearData.portfolioSummary.completedArea.value === 'string' && _reitYearData.portfolioSummary.completedArea.value.includes('msf')
                        ? _reitYearData.portfolioSummary.completedArea.value
                        : parseFloat(_reitYearData.portfolioSummary.completedArea.value).toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + " msf"
                      }
                    </span></div>
                </td>
                <td>
                  <div className={`${displayName}_banner__body_flex`}>
                    <span className={`${displayName}_banner__body_tbl-title`}>MARKET CAPITALIZATION </span>
                    <span className={`${displayName}_banner__body_tbl-value`}>
                      ₹ {_reitYearData.marketPositioning[0].marketCapitalization.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Cr
                    </span></div>
                </td>
                <td>
                  <div className={`${displayName}_banner__body_flex`}>
                    <span className={`${displayName}_banner__body_tbl-title`}>GROSS ASSET VALUE </span>
                    <span className={`${displayName}_banner__body_tbl-value`}>
                      ₹ {_reitYearData.financialMetrics.gAV.value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} Cr
                    </span></div>
                </td>
                {_reitYearData.financialData.distributionYield.value &&
                  <td>
                    <>
                      <div className={`${displayName}_banner__body_flex`}>
                        <span className={`${displayName}_banner__body_tbl-title`}>DISTRIBUTION YIELD</span>
                        <span className={`${displayName}_banner__body_tbl-value`}>
                          {_reitYearData.financialData.distributionYield.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%"}
                        </span></div>
                    </>
                  </td>}
                <td className={_reitYearData.financialData.distributionYield.value ? 'width15' : 'width25'}>
                  <div className={`${displayName}_banner__body_flex`}>
                    <span className={`${displayName}_banner__body_tbl-title`}>SPONSOR & SPONSOR GROUP</span>
                    <div className={`${displayName}_banner__body_tbl-value`}>
                      <span className={`${displayName}_banner__body_tbl-value-sponsors`}>
                        {_sponsors.map(_d => _d).slice(0, 1).join(',\n')}&nbsp;
                      </span>
                      {_sponsors.length > 1 &&
                        <span className={`${displayName}_banner__body_tbl-value-sponsors-link`}
                          onMouseEnter={() => setHoverSponsors(true)}
                          onMouseLeave={() => setHoverSponsors(false)}
                          title={displaySponsors}>
                          ...
                        </span>
                      }
                    </div></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={`${displayName}_banner__foot`}>
          <span className={`${displayName}_banner__foot-description`}>
            {_desc}
          </span>
        </div>



      </div>



    </div>
  )
}

export default ReitBanner;

import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './auth-context';
import { toast } from 'react-toastify';
import service from '../services/service';


export function TimeoutProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (user) {
      if (exemptedRoutes.includes(location.pathname)) return;

      const handleWindowEvents = () => {
        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(async () => {
          toast.warn('Session Timeout Please Login Again', { position: "bottom-right", });
          await service.logout();
          logout();
          navigate('/');
        }, process.env.SESSION_TIMEOUT);
      };

      window.addEventListener('mousemove', handleWindowEvents);
      window.addEventListener('keydown', handleWindowEvents);
      window.addEventListener('click', handleWindowEvents);
      window.addEventListener('scroll', handleWindowEvents);

      handleWindowEvents();

      return () => {
        window.removeEventListener('mousemove', handleWindowEvents);
        window.removeEventListener('keydown', handleWindowEvents);
        window.removeEventListener('click', handleWindowEvents);
        window.removeEventListener('scroll', handleWindowEvents);
      };
    }
  }, [navigate, location.pathname, user, logout]);

  return children;
}

const exemptedRoutes = ['/404', '/sign-up', '/forgot-password'];
import React from "react";
import MapComponent from "./cmp-map";
import { AzureMapsProvider } from 'react-azure-maps';
import { AuthenticationType } from 'azure-maps-control';

interface MapProps {
  center?: {
    lat: number,
    lng: number
  },
  adjPropertiesLoc?: Array<{ lat: number, lng: number, label: string }>;
}

interface REITMapsProps {
  props: MapProps;
}

const AZUREMaps: React.FC<REITMapsProps> = ({ props }) => {
  const mapProp: MapProps = props;
  return (
    <div className="Maps">
      {/* {mapProp.adjPropertiesLoc && mapProp.adjPropertiesLoc.length > 0 && (
        <AzureMapsProvider>
          <MapComponent {...mapProp} />
        </AzureMapsProvider>
      )} */}
    </div>
  );
};

export default AZUREMaps;
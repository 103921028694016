import React, { useState, useEffect } from 'react';
import ReitBanner from './components/banner.jsx';
import AllMetrics from './components/metrics/index.jsx';
import { useLocation } from 'react-router-dom';
import CompareCards from './components/compare-crad/index.jsx';

import './index.less';
import service from '../../services/service.js';

const Portfolio = () => {
  const displayName = 'cmp-portfolio';
  const [reitData, setReitData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const reitId = location.state?.reitId;

  useEffect(() => {
    if (!reitId) {
      setError('REIT ID is not provided');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await service.reitPorfilo(reitId);
        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }
        const jsonData = await response.data;
        setReitData(jsonData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(error.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reitId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!reitData) {
    return null;
  }


  return (
    <div className={`${displayName}`}>
      <ReitBanner props={reitData} />
      <div className={`${displayName}__main`}>
        <div className={`${displayName}__main__allmetrics`}>
          <div className={`${displayName}__main__allmetrics--compare`}>
            {/* <CompareCards props={reitId} /> */}
            </div>
          <AllMetrics reitData={reitData} />
        </div>
        <div className={`${displayName}__main__Cards`}>
          <CompareCards props={reitId} />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

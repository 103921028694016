import React, { useState } from 'react';
import { useAuth } from '../../context/auth-context';
import { toast } from 'react-toastify';
import service from '../../services/service';
import userLogo from '../../assets/mail.svg';
import lockLogo from '../../assets/lock.svg';
import _ from 'lodash';
import './loginForm.less';

const LoginForm = ({ onSwitch, closemodel }) => {
    const displayName = 'cmp-login';
    const { setToken, setUser } = useAuth();

    const [name, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [disableBtn, setDisableBtn] = useState(false);

    const handleLogin = async (e) => {
        setDisableBtn(true);
        e.preventDefault();
        setErrors({});
        const newErrors = validate(name, password);
        if (!_.isEmpty(newErrors)) {
            setErrors(newErrors);
            setDisableBtn(false);
        } else {
            try {
                const response = await service.login(name, password);
                setUser(response);
                setToken(response.access_token);
                toast.success('Login Successful', { zindex: 200000, position: "bottom-right", });
                closemodel();
            } catch (error) {
                let errorMessage = error?.response?.data?.message

                if (!_.isEmpty(errorMessage)) {
                    toast.error(errorMessage, { zindex: 200000, position: "bottom-right", })
                    setErrors({ name: ' ', password: ' ' })
                } else
                    toast.error("Internal Server Error", { zindex: 200000, position: "bottom-right", })
            }
            setDisableBtn(false);
        }
    };

    const validate = (name, password) => {
        const newErrors = {};
        if (_.isEmpty(name)) newErrors.name = 'Username cannot be empty';
        if (_.isEmpty(password)) newErrors.password = 'Password cannot be empty';
        return newErrors;
    }

    return (
        <div className={`${displayName}`}>
            <header className={`${displayName}__header`}>To log in to the application enter your email, password and click on Log in.</header>
            <form className={`${displayName}__form`} onSubmit={handleLogin}>
                <div className={`${displayName}__field ${errors.name ? `${displayName}__field--shake ${displayName}__field--error` : ''}`}>
                    <label className={`${displayName}__label`}>Email ID</label>
                    <div className={`${displayName}__input-area`}>
                        <input
                            className={`${displayName}__input`}
                            type="email"
                            value={name}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter Mail"
                            autoComplete="current-email"
                        />
                        <img className={`${displayName}__icon`} src={userLogo} alt="user icon" />
                        {errors.name && <span className={`${displayName}__error-txt`}>{errors.name}</span>}
                    </div>
                </div>
                <div className={`${displayName}__field ${errors.password ? `${displayName}__field--shake ${displayName}__field--error` : ''}`}>
                    <label className={`${displayName}__label`}>Password</label>
                    <div className={`${displayName}__input-area`}>
                        <input
                            className={`${displayName}__input`}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter Password"
                            autoComplete="current-password"
                        />
                        <img className={`${displayName}__icon`} src={lockLogo} alt="lock icon" />
                        {errors.password && <span className={`${displayName}__error-txt`}>{errors.password}</span>}
                    </div>
                </div>
                <div className={`${displayName}__forget-password`}>
                    <a href="#">Forgot Password?</a>
                </div>
                <div className={`${displayName}__submit-btn`}>
                    <input className={`${displayName}__submit`} type="submit" value="Login" disabled={disableBtn} />
                </div>
            </form>
            <p className={`${displayName}__signup-text`}>
                Are you new? <a className={`${displayName}__link`} onClick={onSwitch} href="#">Sign up</a>
            </p>
        </div>
    );
};

export default LoginForm;

import React from 'react';

const StaicRenderTable = ({ data }) => {
  const headers = [
    ...new Set(
      data.flatMap(data =>
        data.debtMaturityProfile.map(profile => profile.name)
      )
    )
  ].sort();

  return (
    <table className='tableGrid'>
      <thead>
        <tr>
          <th>Years</th>
          {headers.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((data, index) => (
          <tr key={index}>
            <td>{data.year} (₹ Cr.)</td>
            {headers.map(header => {
              const profile = data.debtMaturityProfile.find(
                profile => profile.name === header
              );
              return <td key={header}>{profile ? profile.value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StaicRenderTable;

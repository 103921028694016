import React from "react";
import Header from "./shared/header"
import Portfolio from "./components/reits-portfolio";
import Dashboard from './components/dashboard'
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import CompareReits from "./components/compare-reits";
import CompareAdjacent from "./components/compare-adjacent";
import { AuthProvider } from "./context/auth-context";
import PrivateRoute from "./shared/private-routes";
import Footer from "./shared/footer/index";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SearchProvider } from "./context/search-context";
import EvaluationPage from "./components/evaluation-model";
import BreadCrumbs from "./shared/breadcrumbs";
import "../src/styles/app.less"
import { TimeoutProvider } from "./context/timeout-provider";
import Pricepage from "./components/price-page";

const App = () => {
  const displayName = 'cmp-app'

  const router = [
    {
      path: "/",
      element: <Dashboard></Dashboard>,
    },
    // {
    //   path: "/verify-email",
    //   element: <VerifyEmail></VerifyEmail>,
    // },
    // {
    //   path: "/verified-email-successfully",
    //   element: <VerifiedSuccessfully></VerifiedSuccessfully>,
    // },
    {
      path: "/portfolio-details",
      element: <Portfolio></Portfolio>,
    },
    {
      path: "/compare-reits",
      element: <PrivateRoute element={CompareReits} />,

    },
    {
      path: "/compare-adjacent",
      element: <PrivateRoute element={CompareAdjacent} />,

    },
    {
      path: "/evaluation",
      element: <PrivateRoute element={EvaluationPage} />,

    },
    {
      path:"price",
      element:<Pricepage></Pricepage>
    }

  ];

  return (
    <div className={`${displayName}`}>
      <div className={`example1`}>
        <ToastContainer />
        <AuthProvider>
          <Header />
          <SearchProvider>
            <BrowserRouter>
              <TimeoutProvider>
                <BreadCrumbs />
                <Routes>
                  {router.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))}
                </Routes>
              </TimeoutProvider>
            </BrowserRouter>
          </SearchProvider>
          <Footer />
        </AuthProvider></div>
    </div>
  );
};

export default App;
import { addDays, addYears, format } from 'date-fns';

export const calculateRentalInflow = (leasableArea: number, occupancy: number, averageRental: number) => {
    return (leasableArea * occupancy / 100 * averageRental * 12 / 10 ** 7);
};

export const calculateRentalNOI = (rentalInflow: number, noiPercentage: number) => {
    return ((rentalInflow) * (noiPercentage) / 100);
};

export const calculateAvgRental = (avgRental: number, escalation: number) => {
    return (avgRental * (1 + (escalation) / 100));
};

export const updateCalculatedValues = (cashFlowData: any, columns: any, noiPercentage: number, isEvent: boolean, caprate: number) => {
    const newCashFlowData = [...cashFlowData];
    const leasableArea = parseFloat(newCashFlowData[0].values[0]);

    // Based on the page or modal events
    let idx = isEvent ? 1 : 0;

    while (idx < columns.length) {
        const occupancy = parseFloat(newCashFlowData[1].values[idx]);
        let averageRental = 0;
        if (idx !== 0) {
            let _averageRental = calculateAvgRental(parseFloat(newCashFlowData[2].values[idx - 1]), parseFloat(newCashFlowData[3].values[idx]));
            averageRental = _averageRental;
        }
        else
            averageRental = newCashFlowData[2].values[0];// parseFloat(newCashFlowData[2].values[0].toFixed(2));
        const rentalInflow = calculateRentalInflow(leasableArea, occupancy, averageRental);

        newCashFlowData[2].values[idx] = averageRental;
        newCashFlowData[4].values[idx] = rentalInflow;
        newCashFlowData[5].values[idx] = calculateRentalNOI(rentalInflow, noiPercentage);

        idx++;
    }
    let capRatePercentage = caprate;

    const lastIndex = columns.length - 1;
    const secondLastRentalInflow = (newCashFlowData[4].values[lastIndex - 1]);
    const secondLastRentalNOI = (newCashFlowData[5].values[lastIndex - 1]);


    newCashFlowData[4].values[lastIndex] = ((secondLastRentalInflow * 1.05) / (capRatePercentage / 100));
    newCashFlowData[5].values[lastIndex] = ((secondLastRentalNOI * 1.05) / (capRatePercentage / 100));

    return newCashFlowData;

};

export const extrapolateDate = (valDate: Date) => {
    const result = [];

    result.push(format(new Date(valDate), 'dd MMM yyyy'));

    let tDate = valDate;
    let count = 0;

    while (count < 10) {
        tDate = addYears(new Date(tDate), 1);
        result.push(format(tDate, 'dd MMM yyyy'));
        count++;
    }

    let fDate = addDays(new Date(result[result.length - 1]), 1);
    result.push(format(new Date(fDate), 'dd MMM yyyy'));

    return result;
}

const getOccupancy = (value: string): number => {

    const _value = Number(value.replace('%', ''))
    if (_value > 95)
        return 95
    else
        return _value
}

export const initiateCashflowData = (valuationDate: Date, leaseableArea: number, occupancy: string, avgRental: number, noiPercentage: number) => {
    const columns = extrapolateDate(valuationDate);
    const cashFlowData = [
        { label: 'Leasable Area', values: Array(columns.length - 1).fill(leaseableArea) },
        { label: 'Occupancy % (Assumed)', values: [occupancy.replace('%', ''), ...Array(columns.length - 2).fill(Math.min(Number(occupancy.replace('%', '')), 95))] },
        { label: 'Average Rental', values: [avgRental, ...Array(columns.length - 2).fill(avgRental)] },
        { label: 'Escalation %', values: [0, ...Array(columns.length - 2).fill(5)] },
        { label: 'Rental Inflow', values: Array(columns.length - 1).fill('0') },
        { label: 'NOI @ ' + noiPercentage + '%', values: Array(columns.length - 1).fill('0') },
    ];
    return { columns, cashFlowData }
};


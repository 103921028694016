import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useEffect, useRef, useState } from 'react';

interface MapProps {
  center?: {
    lat: number;
    lng: number;
  };
  adjPropertiesLoc?: Array<{ lat: number; lng: number; label: string }>; //Array<google.maps.LatLng>
}

const mapStyle = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

const MapComponent = (props: MapProps) => {
  const { center, adjPropertiesLoc } = props;
  const [map, setMap] = useState<google.maps.Map>();
  const ref = useRef<HTMLDivElement>();
  const [markerCluster, setMarkerClusters] = useState<MarkerClusterer>();
  const [marker, setMarker] = useState<{ lat: number; lng: number } | undefined>();

  useEffect(() => {
    if (adjPropertiesLoc && adjPropertiesLoc.length) {
      if (ref.current && !map) {
        const newMap = new google.maps.Map(ref.current, {
          center: center,
          zoom: 5,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          styles: mapStyle,
        });
        setMap(newMap);

        const newMarkerCluster = new MarkerClusterer({ map: newMap, markers: [] });
        setMarkerClusters(newMarkerCluster);
      }

      if (markerCluster) {
        markerCluster.clearMarkers();
        if (marker) {
          markerCluster.addMarker(
            new google.maps.Marker({
              position: { lat: marker.lat, lng: marker.lng },
            }),
          );
        }

        if (adjPropertiesLoc) {
          adjPropertiesLoc.forEach(
            (loc: {
              lat: number | google.maps.LatLng | google.maps.LatLngLiteral;
              lng: number | boolean | null | undefined;
              label: any;
            }) => {
              const newMarker = new google.maps.Marker({
                position: new google.maps.LatLng(loc.lat, loc.lng),
                //label: loc.label,
                title: loc.label,
              });
              const infoWindow = new google.maps.InfoWindow({
                headerContent: loc.label,
              });
              newMarker.addListener('click', () => {
                infoWindow.open(map, newMarker);
              });
              markerCluster.addMarker(newMarker);
            },
          );
        }
      }
    }
  }, [map, markerCluster, center, marker, adjPropertiesLoc]);

  return (
    <>
      <div ref={ref as any} style={{ height: '100%', width: '100%', minHeight: '335px' }}></div>
    </>
  );
};

export default MapComponent;

import React, { useState,useEffect } from 'react';

import './index.less'

const Tooltip = ({ content }) => {
  const displayname = "cmp-tool-tip";
  const [visible, setVisible] = useState(false);

  const toggleTooltip = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    let timer;
    if (visible) {
      timer = setTimeout(() => {
        setVisible(false);
      },1000); 
    }
    return () => clearTimeout(timer);
  }, [visible]);

  return (
    <div className={`${displayname}`}>
      <div className={`${displayname}__tooltip_wrapper`} onMouseEnter={toggleTooltip}>
        <button className={`${displayname}__tooltip_button`}>i</button>
        {visible && (
          <div className={`${displayname}__tooltip_content`}>
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./index.less"

function Card({ data }) {

  const displayName = "cmp-card-layout"
  const [hoverLocation, setHoverLocation] = useState(false);

  const navigate = useNavigate();

  const goToPortfolioDetail = (reitId) => {
    navigate('/portfolio-details', { state: { reitId } });
  };

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  const displayLocations = hoverLocation || data.location.length <= 4
    ? data.location.join(',\n')
    : data.location.slice(0, 4).join(', ') + '...';

  return (
    <div className={`${displayName}`} >
      <div className={`${displayName}__header`} >
        <table className={`${displayName}__header-table`}>
          <tbody className={`${displayName}__header-tbody`}>
            <tr className={`${displayName}__header-tr`}>
              <td colSpan={2}>
                <span className={`${displayName}__header-tr--reitname`}>
                  {data.name}
                </span>
              </td>
              {/* TODO : <td className="flex-container" style={{ textAlign: "right" }}> <span>Primary Asset Class: {data.assest_type.map(loc => loc.name).join(', ')}</span></td> */}
            </tr>
            <tr className={`${displayName}__header-tr`}>
              <td className={`${displayName}__header-tr--location`}>
                <span
                  className='banner__locations'
                  onMouseEnter={() => setHoverLocation(true)}
                  onMouseLeave={() => setHoverLocation(false)}
                  title={displayLocations}
                  style={{ cursor: 'pointer' }}
                >
                  {data.location.slice(0, 4).join(', ')} ...
                </span>
              </td>
              <td className={`${displayName}__header-tr--date`}>
                Listing Date: {new Date(data.date).toLocaleDateString('en-US', options)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className={`${displayName}__hr`} />
      <div className={`${displayName}__body`}>
        <table className={`${displayName}__body-table`}>
          <tbody className={`${displayName}__body-table-tbody`}>
            <tr className={`${displayName}__body-table-tbody-topdetails`}>
              <td>
                <div className={`${displayName}__body-table-tbody-topdetails-head`}>
                  <span className={`${displayName}__body-table-tbody-topdetails-head--title`}>
                    BSE
                  </span>
                  <span className={`${displayName}__body-table-tbody-topdetails-head--live`}>
                    (Prev Close)
                  </span>
                </div>
                <p className={`${displayName}__body-table-tbody-topdetails-exchange`}>
                  <span className={`${displayName}__body-table-tbody-topdetails-exchange--bse`}>
                    {data.exchanges[0].openPrice.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </span>
                  <span className={`${displayName}__body-table-tbody-topdetails-exchange-arrow ${displayName}__body-table-tbody-topdetails-exchange-arrow--${data.exchanges[0].openPriceChange.includes('+') ? 'positive' : data.exchanges[0].openPriceChange.includes('-') ? 'negative' : 'neutral'}`}>
                    ({data.exchanges[0].openPriceChange})
                  </span>
                </p>
              </td>
              <td>
              <div className={`${displayName}__body-table-tbody-topdetails-head`}>
                <span className={`${displayName}__body-table-tbody-topdetails-head--title`}>
                  NSE
                </span>
                <span className={`${displayName}__body-table-tbody-topdetails-head--live`}>
                  (Prev Close)
                </span>
                </div>
                <p className={`${displayName}__body-table-tbody-topdetails-exchange`}>
                <span className={`${displayName}__body-table-tbody-topdetails-exchange--bse`}>
                  {data.exchanges[1].openPrice.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </span>
                  <span className={`${displayName}__body-table-tbody-topdetails-exchange-arrow ${displayName}__body-table-tbody-topdetails-exchange-arrow--${data.exchanges[1].openPriceChange.includes('+') ? 'positive' : data.exchanges[1].openPriceChange.includes('-') ? 'negative' : 'neutral'}`}>
                    ({data.exchanges[1].openPriceChange})
                  </span>
                </p>
              </td>
            </tr>
            <tr className={`${displayName}__body-table-tbody-btmdetails`}>
              <td className={`${displayName}__body-table-tbody-btmdetails-cell`}>
                <span className={`${displayName}__body-table-tbody-btmdetails-cell--title`}>
                  Total Portfolio :
                </span>
                <p className={`${displayName}__body-table-tbody-btmdetails-cell--value`}>
                  <span style={{whiteSpace: "pre-wrap"}}>
                    {/* {data.portfolio.value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} msf */}
                    {typeof data.portfolio.value === 'string' && data.portfolio.value.includes('msf')
                        ? data.portfolio.value
                        : parseFloat(data.portfolio.value).toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + " msf"
                      }
                  </span>
                </p>
              </td>
              <td className={`${displayName}__body-table-tbody-btmdetails-cell`}>
                <span className={`${displayName}__body-table-tbody-btmdetails-cell--title`}>
                  Market Cap (NSE):
                </span>
                <p className={`${displayName}__body-table-tbody-btmdetails-cell--value`}>
                  <span >
                    ~ ₹ {data.marketcap.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Cr
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={`${displayName}__footer`}>
        <button className={`${displayName}__footer-btn`} onClick={() => goToPortfolioDetail(data.id)}>Learn More</button>
      </div>
    </div>
  );
}
export default Card;
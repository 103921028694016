import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from '../../../../services/service';

import LoginForm from '../../../login-form';
import SignupForm from '../../../signup-form';
import _ from 'lodash';
import './index.less';
import { useAuth } from '../../../../context/auth-context';
import { toast } from 'react-toastify';
import Modal from '../../../../shared/modal';
import Lock from '../../../../assets/lock_grey.svg';
import LockWhite from '../../../../assets/lock-white.svg';
import { useRef } from 'react';


const CompareCards = ({ props }) => {
  const [cards, setComapreData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoverLocation, setHoverLocation] = useState(false);
  const [imgloc, setImgloc] = useState(Lock);
  const [imgloc2, setImgloc2] = useState(Lock);
  const [imgloc3, setImgloc3] = useState(Lock);
  const { user } = useAuth();
  const divRefs = useRef([]);
  const [hoverHeight, setHoverHeight] = useState([0, 0, 0]);
  const [refsSet, setRefsSet] = useState(false);

  useEffect(() => {
    if (refsSet) {
      const newHeights = divRefs.current.map((div, index) => {
        if (div) {
          return { heightOffSet: div.offsetHeight, topOffSet: div.offsetTop };
        }
        return 0;
      });
      setHoverHeight(newHeights);
    }
  }, [refsSet]);

  const [modalContent, setModalContent] = useState(null);
  const { token, setToken, setUser } = useAuth();


  const navigate = useNavigate();
  const excludedId = props;

  const displayName = "cmp-compare-cards"

  useEffect(() => {
    if (!excludedId) {
      setError('REIT ID is not provided');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await service.comapreCards();
        if (_.isEmpty(response)) {
          throw new Error(`HTTP error! status: ${response}`);
        }
        const jsonData = await response.data;
        setComapreData(jsonData);

      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(error.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [excludedId]);


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!cards) {
    return null;
  }


  const handleCompare = (cardId) => {
    if (user) {
      const idsArray = [excludedId, cardId];
      navigate('/compare-reits', { state: { idsArray } });
    }
    else {
      toast.warn('Please log in to access this page.', { position: "bottom-right" });
    }
  }

  const handleadjacentpro = () => {
    if (user) {
      navigate('/compare-adjacent', { state: { excludedId } });
    }
    else {
      toast.warn('Please log in to access this page.', { position: "bottom-right" });
    }
  }

  const handleValuation = () => {
    if (user) {
      navigate('/evaluation');
    }
    else {
      toast.warn('Please log in to access this page.', { position: "bottom-right" });
    }
  }

  const updatedCards = cards.map((card, index) => ({
    ...card,
    colorClass: index % 2 === 0 ? "orangeLight" : "purpleLight"
  }));


  const displayLocations = (_uniqueLocations) => (
    hoverLocation || _uniqueLocations.length >= 4
      ? _uniqueLocations.join(',\n')
      : _uniqueLocations.slice(0, 4).join(', ') + '...'
  )

  const openLogin = () => setModalContent('login');
  const openSignup = () => setModalContent('signup');
  const closeModal = () => setModalContent(null);

  return (
    <div className={`${displayName}`}>
      <div>
        {!user &&
          <>
            <div className={`${displayName}_lockedPanel`} style={{ height: hoverHeight[0].heightOffSet, top: hoverHeight[0].topOffSet }} onClick={openLogin} onMouseEnter={() => setImgloc(LockWhite)} onMouseLeave={() => setImgloc(Lock)}>
              <div className={`${displayName}_lockedPanel_div`}>
                <div className={`${displayName}_lockedPanel_div_maintitle`}>
                  <span className={`${displayName}_lockedPanel_div_maintitle--text1`}>
                    To access Compare Reits you need to Login
                  </span>
                  <span className={`${displayName}_lockedPanel_div_maintitle--text2`}>
                    Click here to login
                  </span>
                </div>
                <div className={`${displayName}_lockedPanel_div_icon`}>
                  <img className={`${displayName}_lockedPanel_div_icon--img`} src={imgloc} alt='lock' />
                </div>
              </div>
            </div>
            <>
              <Modal isOpen={modalContent !== null} onClose={closeModal}>
                {modalContent === 'login' && <LoginForm onSwitch={openSignup} closemodel={closeModal} />}
                {modalContent === 'signup' && <SignupForm onSwitch={openLogin} closemodel={closeModal} />}
              </Modal>
            </>
          </>
        }
        <div>
          <div className={`${displayName}_title`}>COMPARE REITs IN DETAIL</div>
          <div ref={el => {
            divRefs.current[0] = el;
            setRefsSet(true);
          }}>
            {updatedCards.map(card => {
              if (card.id !== excludedId) {
                return (
                  <div key={card.id}
                    className={`${displayName}__card`}
                    onClick={() => handleCompare(card.id)}>
                    <div className={`${displayName}__card_body`}>
                      <span className={`${displayName}__card_body--name`}>{card.name}</span>
                      <span className={`${displayName}__card_body--assets`}>
                        <span className={`${displayName}__card_body--assets--title`}>Primary Asset Class : </span>
                        <span className={`${displayName}__card_body--assets--value`}>Commercial Office Space</span>
                      </span>
                      <span className={`${displayName}__card_body--locations`}>
                        <span>
                          {card.locations.slice(0, 4).join(', ')}
                          {card.locations.length > 4 &&
                            <span
                              className={`${displayName}__card_body--locations--link`}
                              onMouseEnter={() => setHoverLocation(true)}
                              onMouseLeave={() => setHoverLocation(false)}
                              title={displayLocations(card.locations)}
                              style={{ cursor: 'pointer' }}
                            > & More
                            </span>
                          }
                        </span>
                      </span>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <hr className={`${displayName}_hr`} />

      <div>
        <div className={`${displayName}_title`}>
          COMPARE PROPERTIES
        </div>

        {!user &&
          <>
            <div className={`${displayName}_lockedPanel2`} style={{ height: hoverHeight[1].heightOffSet, top: hoverHeight[1].topOffSet }} onClick={openLogin} onMouseEnter={() => setImgloc2(LockWhite)} onMouseLeave={() => setImgloc2(Lock)}>
              <div className={`${displayName}_lockedPanel2_div`}>
                <div className={`${displayName}_lockedPanel2_div_maintitle`}>
                  <span className={`${displayName}_lockedPanel2_div_maintitle--text1`}>
                    To access Compare Properties you need to Login
                  </span>
                  <span className={`${displayName}_lockedPanel2_div_maintitle--text2`}>
                    Click here to login
                  </span>
                </div>
                <div className={`${displayName}_lockedPanel2_div_icon`}>
                  <img className={`${displayName}_lockedPanel2_div_icon--img`} src={imgloc2} alt='lock' />
                </div>
              </div>
            </div>
          </>
        }

        <div className={`${displayName}__card`} onClick={() => handleadjacentpro()} ref={el => divRefs.current[1] = el}>
          <div className={`${displayName}__card_body`}>
            <span className={`${displayName}__card_body--name`}>Compare Properties</span>
            <span className={`${displayName}__card_body--assets`}>Lets you compare properties in Metro cities</span>
          </div>
        </div>
      </div>
      <hr className={`${displayName}_hr`} />
      <div>
        <div className={`${displayName}_title`}>
          VALUATION
        </div>
        {!user &&
          <>
            <div className={`${displayName}_lockedPanel3`} style={{ height: hoverHeight[2].heightOffSet, top: hoverHeight[2].topOffSet }} onClick={openLogin} onMouseEnter={() => setImgloc3(LockWhite)} onMouseLeave={() => setImgloc3(Lock)}>
              <div className={`${displayName}_lockedPanel3_div`}>
                <div className={`${displayName}_lockedPanel3_div_maintitle`}>
                  <span className={`${displayName}_lockedPanel3_div_maintitle--text1`}>
                    To access Valuation you need to Login
                  </span>
                  <span className={`${displayName}_lockedPanel3_div_maintitle--text2`}>
                    Click here to login
                  </span>
                </div>
                <div className={`${displayName}_lockedPanel3_div_icon`}>
                  <img className={`${displayName}_lockedPanel3_div_icon--img`} src={imgloc3} alt='lock' />
                </div>
              </div>
            </div>
          </>
        }
        <div className={`${displayName}__card`} onClick={() => handleValuation()} ref={el => divRefs.current[2] = el}>
          <div className={`${displayName}__card_body`}>
            <span className={`${displayName}__card_body--name`}>Check Valuation</span>
            <span className={`${displayName}__card_body--assets`}>Lets you check any properties valuation in Metro cities</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompareCards;
import React from "react";
import { useLocation } from 'react-router-dom';
import FinancialComparisonTable from "./reit-compare-table/reit-compare-table";

import './index.less';

const CompareReits = () => {
    const displayName ='cmp-compare-reit'
    const location = useLocation();
    const { idsArray } = location.state;
    return (
        <>
            <div className={`${displayName}`}>
                <FinancialComparisonTable props={idsArray}/>
            </div>
        </>
    )
}

export default CompareReits;
[
    {
        "name": "Embassy Office Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2024",
        "isYearly": false,
        "data": [
            "Debt Maturity profile for Q3 FY 2024 & Q4 FY 2024 reflects changes to the debt subsequent to quarter end."
        ]
    },
    {
        "name": "Embassy Office Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2023",
        "isYearly": false,
        "data": [
            "FY-28 debt maturity for Q1 FY 2023, Q2 FY 2023 & Q3 FY 2023 represents the maturity for FY-28 & beyond."
        ]
    },
    {
        "name": "Embassy Office Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2024",
        "isYearly": true,
        "data": [
        ]
    },
    {
        "name": "Embassy Office Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2023",
        "isYearly": true,
        "data": [
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2023",
        "isYearly": true,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2024",
        "isYearly": true,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2022",
        "isYearly": true,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2021",
        "isYearly": true,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2023",
        "isYearly": false,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2024",
        "isYearly": false,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2022",
        "isYearly": false,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Brookfield India REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2021",
        "isYearly": false,
        "data": [
            "The data reflects external debt maturity, excluding CCDs and NCDs."
        ]
    },
    {
        "name": "Nexus Select Trust",
        "tableFor": "Debt Maturity Profile",
        "year": "2024",
        "isYearly": true,
        "data": [
        ]
    },
    {
        "name": "Mindspace Business Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2024",
        "isYearly": true,
        "data": [
        ]
    },
    {
        "name": "Mindspace Business Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2023",
        "isYearly": true,
        "data": [
        ]
    },
    {
        "name": "Mindspace Business Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2022",
        "isYearly": true,
        "data": [
        ]
    },
    {
        "name": "Mindspace Business Parks REIT",
        "tableFor": "Debt Maturity Profile",
        "year": "2021",
        "isYearly": true,
        "data": [
        ]
    }
]
import React, { useState, useEffect } from 'react';

import './index.less';
import RiskPremium from './risk-premium';
import WACC from './wacc';
import { useEvaluationState } from '../../../../context/evaluation-context';
import { calculateXNPV } from '../../../../services/wacc-service';
import { toast } from 'react-toastify';
import Tooltip from '../../../../shared/tool-tip';

const WaccCal = ({ onSwitch, closeModal }) => {
  const displayName = 'cmp-wacc-flow';

  const { evalState, setEvalState } = useEvaluationState();

  const [totalwacc, setTotalwacc] = useState('0');
  const [selectedExchange, setSelectedExchange] = useState('waccal');

  useEffect(() => {
    const finalwacc = (parseFloat(evalState.riskPremium) + parseFloat(evalState.waccRange)).toFixed(2)
    setTotalwacc(finalwacc);

    const valuation = parseFloat((calculateXNPV(finalwacc, evalState.cashFlowData[5].values.slice(1), evalState.columns)).toFixed(2));

    setEvalState((prevState) => ({
      ...prevState,
      waccDiscountRateUser: finalwacc,
      valuationUser: valuation,
    }));
  }, [evalState.cashFlowData, evalState.columns, evalState.riskPremium, evalState.waccRange, setEvalState]);

  const Calculate = () => {
    toast.success('Calculation done!', { zindex: 200000, position: "bottom-right", });
  }

  return (

    <div className={`${displayName}`}>
      <div className={`${displayName}__nav`}>
        <div className={`${displayName}__navbar`}>
          <button
            className={` ${selectedExchange == 'cashflow' ? `${displayName}__navbar-btn--active` : `${displayName}__navbar-btn`}`}
            onClick={() => {
              setSelectedExchange('cashflow');
              onSwitch();
            }}
            disabled={selectedExchange === 'cashflow'}
          >
            Cash Flow based on Different Scenarios
          </button>
          <button
            className={` ${selectedExchange == 'waccal' ? `${displayName}__navbar-btn--active` : `${displayName}__navbar-btn`}`}
            onClick={() => {
              setSelectedExchange('waccal');
            }}
            disabled={selectedExchange === 'waccal'}
          >
            WACC Calculation
          </button>
        </div>
      </div>
      <div className={`${displayName}__body`}>
        <div className={`${displayName}__body-panel`}>
          <div className={`${displayName}__body-panel--model`}>
            <div className={`${displayName}__body-panel--model--risk`}>
              <RiskPremium />
            </div>
            <div className={`${displayName}__body-panel--model--cost`}>
              <WACC />
            </div>
          </div>
          <div className={`${displayName}__body-panel--rate`}>

            <div className={`${displayName}__body-panel--rate--txt`}>
              <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative" }}>
                <div>Total Discount Rate</div>
                <div style={{ top: "-8px", position: 'relative' }}>
                  {<Tooltip content={'It is the interest rate used to calculate the present value of future cash flows from an asset or investment.'} />}
                </div>
              </div>
            </div>

            <div className={`${displayName}__body-panel--rate--value`}>
              {totalwacc}%
            </div>
          </div>
        </div>
      </div>

      <div className={`${displayName}__control`}>
        <button className={`${displayName}__control--cancel`} style={{ margin: '10px' }} onClick={() => closeModal()}>Cancel</button>
        <button className={`${displayName}__control--back`} style={{ margin: '10px' }} onClick={() => { setSelectedExchange('cashflow'); onSwitch() }}>Back</button>
        <button className={`${displayName}__control--calculate`} style={{ margin: '10px' }} onClick={() => { closeModal(); Calculate() }}>Calculate</button>
      </div>
    </div>
  );
};

export default WaccCal;

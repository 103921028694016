import React, { useState } from 'react';
import './index.less';
import Modal from '../modal';

const Footer = () => {
  const displayName = 'cmp-footer';

  const [modalContent, setModalContent] = useState(null);

  const openDiscaimer = () => setModalContent('login');
  const closeModal = () => setModalContent(null);

  return (
    <div>
      <Modal isOpen={modalContent !== null} onClose={closeModal} title={'Disclaimer'}>
        <div style={{ fontSize: "13px", lineHeight: "16px" }}>
          <p>The benchmarks, rankings, score, findings, studies, reports and outcomes (“Material”) that are made available by CARE Analytics and Advisory Pvt. Ltd. (“CareEdge Analytics”) on the platform or website are solely for informational purposes, and shall not be construed as any accounting, legal, regulatory, tax, research, or investment advice. They do not take into account any specific or general investment objectives. The Material does not constitute a recommendation to buy, sell, hold or otherwise deal with the entities identified or contemplated in the evaluation or with their assets in any manner. The users shall exercise discretion, judgement and caution before acting on anything contained in the Material and shall consider whether the results obtained through the platform or website are suitable to their circumstances, and seek professional advice, where appropriate and necessary.</p>
          <p>The data used to compile the Material is based on information publicly disclosed by the entities. The Material contained herein, and any outcome from application of the valuation model is based on certain assumptions, methodology, and analysis of available information. The analysis and findings are based on review of the available information or financial performance (except financial projections) and reflects opinion that represents reasonable expectations or estimates for the entity at a particular point in time; however such information, estimates or opinion are not offered as predictions or as assurance that a particular outcome will be achieved, that certain events will occur, or that a particular level for profitability will be achieved. The findings, interpretations and conclusions obtained from the platform do not in any manner reflect the views of CareEdge Analytics and users are advised to make their own assumptions and arrive at conclusions suitable to their specific situation. It is possible that the entities which are subject of the evaluation under the platform or website may have existing or future credit rating engagements with CARE Rating Limited; all such engagements are on mutually exclusive basis and any data, information or Material available on the platform or website is not influenced by such rating engagements in any manner whatsoever.</p>
          <p>While the platform and the models on which it operates have been designed and prepared in good faith, neither CareEdge Analytics nor any of its agents, representatives, advisers, affiliates, directors, officers or employees accept any responsibility for or make any representation or warranty, either express or implied, as to the accuracy, reliability or completeness of the information contained herein or any other information made available in connection with the evaluation. CareEdge Analytics disclaims all liability for any losses, claims, damages or liabilities arising out of the actions taken, omissions or reliance placed upon by any party on the Material or usage of such information or Material and any loss, damages, cost or expenses arising in any way from fraudulent acts, misrepresentations or willful default on the part of entities to whom the data belongs.</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <button className="close-btn" onClick={closeModal}>Close</button>
        </div>
      </Modal>
      <div className={`${displayName}`}>
        <div className={`${displayName}__discailmer`}>
          <span onClick={openDiscaimer}><span className={`${displayName}__text-a`}>Disclaimer</span></span>
        </div>
        <div className={`${displayName}__text`}>
          Copyright © CARE Analytics And Advisory Private Limited, 2024. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;

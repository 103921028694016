import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import './index.less';
import EditIcon from '../../../../assets/edit.svg';
import Modal from '../../../../shared/modal';
import CashFlowCal from '../cash-flow-calculation';
import WaccCal from '../wacc-calculation';
import { useEvaluationState } from '../../../../context/evaluation-context';
import { initiateCashflowData, updateCalculatedValues } from '../../../../services/cashflow-service';
import { useSelectedCity, useSelectedProperty } from '../../../../context/search-context';
import { calculateXNPV, initialcalculateWACC } from '../../../../services/wacc-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../../shared/tool-tip';
import service from '../../../../services/service';

const ValuationTable = () => {
  const displayName = 'cmp-evaluation-table';
  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const [valuationData, setValuationData] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const { evalState, setEvalState } = useEvaluationState();

  const [selectedCity] = useSelectedCity();
  const [selectedProperty] = useSelectedProperty();

  const [basedReport, setbasedReport] = useState(null);
  const [basedReportValue, setbasedReportValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await service.valautionAdjacnet(selectedProperty.value);
        const data = response.data;
        if (!data) {
          toast.error('Failed to fetch valuation data', { position: "bottom-right", });
          return
        }
        setbasedReport(data.basedReport);
        setbasedReportValue(data.basedReportValue);
        if (data.historicalData.length > 0) {
          setValuationData(data);

          let initialDate = data.historicalData[0].date;
          const cashFlowDataPreFetch = initiateCashflowData(new Date(initialDate), parseFloat(data.historicalData[0].leasableArea) * 1000000, data.occupancy, data.currentRent, parseFloat(evalState.noiPercentage))
          const newData = updateCalculatedValues(cashFlowDataPreFetch.cashFlowData, cashFlowDataPreFetch.columns, evalState.noiPercentage, false, data.capRate);

          const initialWACC = initialcalculateWACC(data.location, data.wale, data?.esgCompliance, data?.buildingAge, data?.vacancy)

          const valuationCare = parseFloat((calculateXNPV(initialWACC.WACCResults.waccDiscountRate, cashFlowDataPreFetch.cashFlowData[5].values.slice(1), cashFlowDataPreFetch.columns)).toFixed(2));

          setEvalState((prevState) => ({
            ...prevState,
            valuationDate: initialDate,
            leaseableArea: data.historicalData[0].leasableArea,
            occupancy: data.occupancy,
            avgRental: data.currentRent,
            columns: cashFlowDataPreFetch.columns,
            cashFlowData: newData,
            capRate: data.capRate,

            costOfEquity: initialWACC.waccRange.costOfEquity,
            costOfDebt: initialWACC.waccRange.costOfDebt,
            weightOfDebt: initialWACC.waccRange.weightOfDebt,
            weightOfEquity: initialWACC.waccRange.weightOfEquity,
            waccRange: initialWACC.WACCResults.calculatedWacc,

            location: data.location,
            tenantProfile: initialWACC.riskPremiums.tenantProfile,
            wale: initialWACC.riskPremiums.wale,
            buildingLife: initialWACC.riskPremiums.buildingLife,
            vacancy: initialWACC.riskPremiums.vacancy,
            propertyType: initialWACC.riskPremiums.propertyType,
            esgCompliance: initialWACC.riskPremiums.esgCompliance,
            riskPremium: initialWACC.WACCResults.totalRiskPremium,

            waccDiscountRate: initialWACC.WACCResults.waccDiscountRate,
            waccDiscountRateUser: initialWACC.WACCResults.waccDiscountRate,
            valuationUser: valuationCare,
            valuationCare: valuationCare
          }));
        }
      } catch (error) {
        setError(error.message || 'Failed to fetch data');
        console.error('Failed to fetch valuation data:', error);
      }
    };

    if (selectedProperty) {
      fetchData();
    }
    else {
      toast.info("Please Select Property", { position: "bottom-right", });
      return
    }
  }, [error, evalState.noiPercentage, selectedProperty, setEvalState]);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const selectedLeaseableArea = valuationData.historicalData.find((data) => data.date === selectedDate);

    const cashFlowDataUpdate = initiateCashflowData(new Date(selectedDate), parseFloat(selectedLeaseableArea.leasableArea), evalState.occupancy, evalState.avgRental, parseFloat(evalState.noiPercentage))
    const updatedData = updateCalculatedValues(cashFlowDataUpdate.cashFlowData, cashFlowDataUpdate.columns, evalState.noiPercentage, false, evalState.capRate);
    setEvalState((prevState) => ({
      ...prevState,
      valuationDate: selectedDate,
      leaseableArea: selectedLeaseableArea.leasableArea,
      columns: cashFlowDataUpdate.columns,
      cashFlowData: updatedData
    }));
  };

  if (!valuationData) {
    return <p>Loading...</p>;
  }

  if (error) {
    toast.error("Internal Server Error", { zindex: 200000, position: "bottom-right", })
    navigate("/");
  }

  const renderDropdownOptions = () => {
    return valuationData.historicalData.map((data) => {
      return (<option key={data.date} value={data.date}>
        {data.date && format(new Date(data.date), 'MMM do, yyyy')}
      </option>)
    })
  }

  const openLogin = () => setModalContent('cashflow');
  const openSignup = () => setModalContent('waccal');
  const closeModal = () => setModalContent(null);

  const definitions = {
    "Leasable Area (in msf)": "Total Leasable Area is the sum of Completed Area, Under Construction Area and Future Development Area.",
    "Leased Area (in msf)": "The Completed Area of a property which has been leased or rented out in accordance with an agreement entered into for the purpose and also includes Letter of Intents (LoI).",
    "Occupancy (%)": "Occupancy is defined as the ratio of the Occupied Area and the Completed Area.",
    "Vacancy (%)": "The percentage of units or spaces in a rental property that are currently unoccupied.",
    "Effective current rent (Rs/sq.ft/month)": "Base Rent for a specified month per sqauare feet.",
    "WALE (years)": "Weighted Average Lease Expiry based on area. Calculated assuming tenants exercise all their renewal options post expiry of their initial commitment period.",
  };

  const formatNumber = (number) => {
    if (number === null || number === undefined) return '-';
    const roundedNumber = Math.round(number * 100) / 100;
    return roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(1);
  };

  return (
    <div className={`${displayName}`}>
      <div className={`${displayName}__container`}>
        <div className={`${displayName}__container_selector`}>
          <label className={`${displayName}__container_selector-label`}>Valuation Date</label>
          <select
            className={`${displayName}__container_selector-dropdown`}
            value={evalState.valuationDate}
            onChange={handleDateChange}
          >
            {renderDropdownOptions()}
          </select>
        </div>

        <div className={`${displayName}__container_static-table`}>
          <table className={`${displayName}__container_static-table__tbl`}>
            <tbody>
              <tr>
                <td>Name of Asset</td>
                <td>{valuationData.assetName || 'null'}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{valuationData.location || 'null'}</td>
              </tr>
              <tr>
                <td>Construction Status</td>
                <td>{valuationData.constructionStatus || 'null'}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>Leasable Area (in msf/ Hotel keys in nos./ MW)</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions['Leasable Area (in msf)'] && <Tooltip content={definitions['Leasable Area (in msf)']} />}
                    </div>
                  </div>
                </td>
                <td>{evalState.leaseableArea.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || 'null'}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>Leased Area (in msf/ Hotel keys in nos./ MW)</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions['Leased Area (in msf)'] && <Tooltip content={definitions['Leased Area (in msf)']} />}
                    </div>
                  </div>
                </td>
                <td>{valuationData.leasedArea ? valuationData.leasedArea.toFixed(1) : '-'}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>Occupancy (%)</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions['Occupancy (%)'] && <Tooltip content={definitions['Occupancy (%)']} />}
                    </div>
                  </div>
                </td>
                <td>{valuationData.occupancy ? valuationData.occupancy.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +"%" : '-'}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>Vacancy (%)</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions['Vacancy (%)'] && <Tooltip content={definitions['Vacancy (%)']} />}
                    </div>
                  </div>
                </td>
                {/* <td>{valuationData.vacancy ? valuationData.vacancy.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</td> */}
                <td>{valuationData.vacancy ? formatNumber(valuationData.vacancy).toLocaleString('en-IN')+"%" : '-'}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>Effective current rent (Rs/sq.ft/month)</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions['Effective current rent (Rs/sq.ft/month)'] && <Tooltip content={definitions['Effective current rent (Rs/sq.ft/month)']} />}
                    </div>
                  </div>
                </td>
                <td>{valuationData.currentRent ? valuationData.currentRent.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}</td>
              </tr>
              <tr>
                <td>Market rent (Rs/sq.ft/month)</td>
                <td>{valuationData.marketRent ? valuationData.marketRent.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>WALE (years)</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions['WALE (years)'] && <Tooltip content={definitions['WALE (years)']} />}
                    </div>
                  </div>
                </td>
                <td>{valuationData.wale || '-'}</td>
              </tr>
              <tr>
                {/* <td>Age of the Building (years)</td>
                <td>{valuationData.buildingAge || '-'}</td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={`${displayName}__discout-header`}>
        <div className={`${displayName}__discout-header-txt`}>Valuation using 10-Year Discounted Cash Flow Method</div>
      </div>

      <div className={`${displayName}__boxes`}>
        <div className={`${displayName}__boxes-modal`}>
          <div className={`${displayName}__boxes-modal-header`}>
            <span className={`${displayName}__boxes-modal-header-txt`}>CareEdge Standard Valuation Model</span>
          </div>
          <div className={`${displayName}__boxes-modal-body`}>
            <div className={`${displayName}__boxes-modal-body-txt`}>
              Effective Discount Rate @ {evalState.waccDiscountRate.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
            </div>
            <div className={`${displayName}__boxes-modal-body-value`}>Valuation (Rs. Crore) - {Number(evalState.valuationCare.toFixed(2)).toLocaleString('en-IN')}</div>
          </div>
        </div>

        <div className={`${displayName}__boxes-modal`}>
          <div className={`${displayName}__boxes-modal-header`}>
            <span className={`${displayName}__boxes-modal-header-txt`}>Based on Users Assumption</span>
          </div>
          <div className={`${displayName}__boxes-modal-body`}>
            <div className={`${displayName}__boxes-modal-body-txt`}>
              Effective Discount Rate @ {evalState.waccDiscountRateUser.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
            </div>
            <div className={`${displayName}__boxes-modal-body-value`}>
              Valuation (Rs. Crore) - {Number(evalState.valuationUser.toFixed(2)).toLocaleString('en-IN')}
              <img src={EditIcon} alt='Edit' onClick={openLogin} title='Click Here' />
              <Modal isOpen={modalContent !== null} onClose={closeModal} title={'Based on Users Assumptions'}>
                {modalContent === 'cashflow' && <CashFlowCal onSwitch={openSignup} closeModal={closeModal} />}
                {modalContent === 'waccal' && <WaccCal onSwitch={openLogin} closeModal={closeModal} />}
              </Modal>
            </div>
          </div>
        </div>

        <div className={`${displayName}__boxes-modal`}>
          <div className={`${displayName}__boxes-modal-header`}>
            <span className={`${displayName}__boxes-modal-header-txt`}>Based on Market Report</span>
          </div>
          <div className={`${displayName}__boxes-modal-body`}>
            <div className={`${displayName}__boxes-modal-body-txt`}>
              Effective Discount Rate @ {basedReport}%
            </div>
            <div className={`${displayName}__boxes-modal-body-value`}>Valuation (Rs. Crore) - {basedReportValue ? Number(basedReportValue.toFixed(2)).toLocaleString('en-IN') : ''}</div>
          </div>
        </div>
      </div>

      <div className={`${displayName}__disclaimer`}>
        <p>
          <span>{`Disclaimer :   `}</span>
          <b>Methodology - 10 - Year Discounted Cash Flow Method</b>
        </p>
        <br />
        <p>
          The income approach is based on the premise that value of an income - producing asset is a function of future benefits and income derived from that asset.
        </p>
        <br />
        <p>
        Net operating income (NOI) has primarily been used to arrive at the value of the commercial office assets. The projected future cash flows from the property are based on existing lease terms for the operational area till the expiry of the leases or re-negotiation, whichever is earlier. These cash flows have been projected for 10-year duration from the date of valuation and 10th year NOI is used for assessment of terminal value. NOI of 10th year is capitalised at an appropraiate rate to arrive at the terminal value. These future cash flows and terminal value are then discounted to present-day value (valuation date) at an appropriate discount rate.
        </p>
        <br />
        <p>Note: This valuation model is exclusively designed for completed commercial office and retail spaces. It is not applicable to properties under construction. It may also be noted that there may be a 10-15% variance between published valuations and the results generated by the model and the variation could be higher for the properties where the occupancy rates are below 80%.</p>
      </div>
    </div>
  );
};

export default ValuationTable;

import React from 'react';
import Tooltip from '../../../../../../shared/tool-tip';

const RangeSlider = ({ label, value, min, max, step, onChange }) => {
    const definitions = {
        'Cost of Debt (%)': "The cost of debt is the effective interest rate that an entity owes on its external liabilities, such as bonds and loans. Low cost of debt means it is cheaper for the entity to borrow.",
        'Weight of Debt (%)': "The percentage of debt of the total capital structure of the company.",
        'Cost of Equity (%)': "The cost of equity is the return an entity pays to its equity investors, i.e., shareholders, in the form of dividend growth, to compensate for the risk they undertake by investing their capital in the entity.",
        'Weight of Equity (%)': "The proportion of company's capital structure represented by equity.",
    };
    return (
        <div className="range-slider-container">

            <label>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px", position: "relative"}}>
                    <div>{label}</div>
                    <div style={{ top: "-8px", position: 'relative', fontSize:"12px" }}>
                        {definitions[label] && <Tooltip content={definitions[label]} />}
                    </div>
                </div>
            </label>


            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "2px" ,alignItems: "center"}}>
                <div style={{ width: "65%" }}>
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={value}
                        step={step}
                        onChange={onChange}
                    />
                </div>
                <div style={{ width: "25%" }}>
                    <input
                        min={min}
                        max={max}
                        type="number"
                        value={value}
                        onChange={onChange}
                        className="range-slider-input"
                    />
                </div>
            </div>
        </div>
    );
};

export default RangeSlider;
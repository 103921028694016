import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

import './index.less';

const TimeseriesChart = ({ marketPrice, dimensions }) => {

    const displayName = 'cmp-time-series-chart';

    const ref = useRef();
    const [selectedExchange, setSelectedExchange] = useState('nse');

    useEffect(() => {
        if (!marketPrice) return;

        const margin = { top: 20, right: 20, bottom: 50, left: 50 };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;

        d3.select(ref.current).selectAll("*").remove(); // Clear previous chart

        // Extracting data
        const data = marketPrice.find(item => item.exchangeName === selectedExchange)?.historicalData || [];

        // Extracting min and max values
        const minValue = d3.min(data, d => d.value);
        const maxValue = d3.max(data, d => d.value);

        // Calculate the range with an interval of 20
        const yRange = d3.range(Math.ceil((minValue - 40) / 20) * 20, Math.floor((maxValue + 40) / 20) * 20 + 1, 20);

        // Setting the domain for the y-scale
        const y = d3.scaleLinear()
            .domain([yRange[0], yRange[yRange.length - 1]]) // Adjusted domain to start from min value + 10 and end at max value + 10
            .range([height, 0]);

        // Setting up other chart elements...
        const svg = d3.select(ref.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const x = d3.scaleTime()
            .domain(d3.extent(data, d => new Date(d.date)))
            .range([0, width]);

        const xAxis = d3.axisBottom(x)
            .ticks(d3.timeYear.every(1))
            .tickFormat(d3.timeFormat('%Y'))
            .tickSizeOuter(0)
            .tickSize(0); // Remove ticks

        const yAxis = d3.axisLeft(y).tickValues(yRange).tickSizeOuter(0)
            .tickSize(0); // Remove ticks

        const lineColor = selectedExchange === 'nse' ? '#0096FF' : '#FF630F';

        const line = d3.line()
            .x(d => x(new Date(d.date)))
            .y(d => y(d.value));

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", lineColor)
            .attr("stroke-width", 1.5)
            .attr("d", line);

        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(xAxis)
            .call(g => g.select(".domain").remove())
            .selectAll(".tick text")
            .attr("dy", "1.5em"); // Adjust vertical position of x-axis labels

        svg.append("g")
            .call(yAxis)
            .call(g => g.select(".domain").remove())
            .selectAll(".tick text")
            .attr("dy", "0.5em") // Adjust vertical position of y-axis labels
            .attr("dx", "-0.5em"); // Adjust horizontal position of y-axis labels

        svg.selectAll("g.tick")
            .select("line.grid-line")
            .data(y.ticks())
            .join("line")
            .attr("class", "grid-line")
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", d => y(d))
            .attr("y2", d => y(d))
            .attr("stroke", "grey")
            .attr("stroke-opacity", 0.5)
            .attr("stroke-dasharray", "0");

    }, [marketPrice, dimensions, selectedExchange]);

    return (
        <div className={`${displayName}`}>
            <div className={`${displayName}__navbar`}>
                <button className={`${displayName}__navbar-btn ${selectedExchange == 'nse' ? `${displayName}__navbar-btn--active` : ''}`} onClick={() => setSelectedExchange('nse')} disabled={selectedExchange === 'nse'}>NSE</button>
                <button className={`${displayName}__navbar-btn ${selectedExchange == 'bse' ? `${displayName}__navbar-btn--active` : ''}`} onClick={() => setSelectedExchange('bse')} disabled={selectedExchange === 'bse'}>BSE</button>
            </div>
            <div ref={ref} style={{overflowX:"auto"}}></div>
            <div className={`${displayName}__legend`} >
                <div className={`${displayName}__circle`} style={{ backgroundColor: selectedExchange === 'nse' ? '#0096FF' : '#FF630F' }}></div>
                <div> {selectedExchange.toUpperCase()}</div>
            </div>
        </div>
    );
};
export default TimeseriesChart;

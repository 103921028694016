import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data, dimensions, label }) => {
  const ref = useRef();
  const tooltipRef = useRef();
  const customColors = ["#0FDCD2","#14324E","#A5A5A5","#416D91","#5B9AD5"];
  useEffect(() => {
    const radius = Math.min(dimensions.width, dimensions.height) / 2;

    const svg = d3.select(ref.current)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .append("g")
      .attr("transform", `translate(${dimensions.width / 2}, ${dimensions.height / 2})`);

    const color = d3.scaleOrdinal(customColors);

    const pie = d3.pie()
      .value(d => d.value);

    const arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(0);

    const arcs = svg.selectAll(".arc")
      .data(pie(data))
      .enter().append("g")
      .attr("class", "arc");

    arcs.append("path")
      .attr("d", d => arc(d))
      .attr("fill", (d, i) => color(i))
      .on("mouseover", function (event, d) {
        const [x, y] = d3.pointer(event);
        d3.select(tooltipRef.current)
          .style("display", "block")
          .html(`${d.data.label}: ${d.data.value}`);
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", d3.arc().innerRadius(0).outerRadius(radius + 0));
      })
      .on("mousemove", function (event) {
        const [x, y] = d3.pointer(event);
        const tooltip = d3.select(tooltipRef.current);
        tooltip.style("left", `${x + 300}px`)
          .style("top", `${y + 120}px`);
      })
      .on("mouseout", function () {
        d3.select(tooltipRef.current)
          .style("display", "none");
        d3.select(this)
          .transition()
          .duration(200)
          .attr("d", d => arc(d));
      });
  }, [data, dimensions]);

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", position: "relative", padding: "20px" }}>
      <svg ref={ref}></svg>
      <div >
        <div ref={tooltipRef} style={{ display: 'flex', position: "absolute", backgroundColor: "#2fbeb7", fontFamily: "Gotham-Medium", fontSize: "14px", borderRadius: "8px", padding: "4px" }}></div>
        <div style={{ alignItems: "flex-start" }}>
          <span>
            {
              label &&
              <>
                <span style={{ color: "#696969"}}>
                  {label[0] + " : "}
                </span>
                <span style={{ color: "#121212", fontFamily: "Gotham-Medium", whiteSpace: "pre-wrap",display:"inline-flex"}}>
                  {label[1]}
                </span>
              </>
            }
          </span>

          <table>
            <tbody>
              <tr >
                <td>

                </td>
              </tr>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div key={index} style={{ display: "flex", alignItems: "center", flexDirection: "row", fontSize: "18px" }}>
                      <span style={{ border: "1px", 'borderRadius': '50px', fontSize: "4px", backgroundColor: customColors[index % customColors.length], width: '14px', height: '14px', marginRight: '5px' }}></span>
                      <span style={{ backgroundColor: 'white' }} >
                        <span style={{ color: "#696969" }}>{item.label}: </span>
                        <span style={{ color: "#121212", fontFamily: "Gotham-Medium" }}>
                          {item.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
              <tr >
                <td>

                </td>
              </tr>
            </tbody>
          </table>
          <span>
            {
              label &&
              <span style={{ color: "#696969" }}>
                {label[2]}
              </span>
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default PieChart;

import React, { useState } from 'react';
import './ToggleButton.less'; 

const ToggleButton = ({ onToggle }) => {
    const displayName = 'cmp-toggle-switch';
    const [isYearly, setIsYearly] = useState(true); // Default to yearly

    const handleToggle = () => {
        const newValue = !isYearly;
        setIsYearly(newValue);
        onToggle(newValue); // Pass the current state to the parent component
    };

    return (
        <div className={`${displayName}`} onClick={handleToggle}>
        <div className={`${displayName}__label-container`}>
            <span className={`${displayName}__label ${isYearly ? '' : `${displayName}__label--inactive`}`}>
                Yearly
            </span>
            <span className={`${displayName}__label ${isYearly ? `${displayName}__label--inactive` : ''}`}>
                Quarterly
            </span>
        </div>
        <div className={`${displayName}__knob ${isYearly ? `${displayName}__knob--yearly` : `${displayName}__knob--quarterly`}`} >{isYearly ? 'Yearly' : 'Quarterly'}</div>
    </div>
    );
};

export default ToggleButton;

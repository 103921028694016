import React from 'react';
import BannerImg from '../../assets/Banner.png';
import './index.less';

const HeroBanner = () => {
  const textOnImage = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    position: 'absolute',
    width: '852px',
    height: '100px',
    left: 'calc(50% - 852px / 2)',
    top: '200px',//'140px',
    zIndex: '100',
  };

  const headimage = {
    width: '100%',
    left: '0px',
    top: '56px',
    background: 'black',
    mixBlendMode: 'multiply',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  };

  const displayName = 'cmp-hero-banner';

  return (
    <>
      <div className={`${displayName}`}>
        <div className={`${displayName}__banner`} style={textOnImage}>
          <span className={`${displayName}__banner-title`}>Compare REITs and make Informed Decisions</span>
          <span className={`${displayName}__banner-subtitle`}>
            Whether you’re a seasoned investor or just starting out, our comparison tools will help you find the right<br/>
            platform to achieve your financial goals{' '}
          </span>
        </div>
        <div className={`${displayName}__container`} >
          <img src={BannerImg} className={`${displayName}__container--img`} alt='Freedom Blog'  />
        </div>
      </div>
    </>
  );
};

export default HeroBanner;

import React from "react";
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import MapComponent from "./cmp-map";
interface MapProps {
  center?: {
    lat: number,
    lng: number
  },
  adjPropertiesLoc?: Array<{ lat: number, lng: number, label: string }> //Array<google.maps.LatLng>
}

interface REITMapsProps {
  props: MapProps;
}

const REITMaps: React.FC<REITMapsProps> = ({ props }) => {
  const mapProp: MapProps = props;
  const render = (status: Status) => (<h1>Loading Map</h1>)
  return (
    <div className="Maps">
      <Wrapper apiKey={`${process.env.GOOGLE_MAPS_API_KEY}`} render={render}>
        <MapComponent {...mapProp} />
      </Wrapper>
    </div>
  );
}

export default REITMaps;
import React, { useState } from 'react';
import Tooltip from '../../../../../shared/tool-tip';
import PieIconActive from '../../../../../assets/Chart-pie-fill.svg';
import PieIcon from '../../../../../assets/chart-pie-stroke.svg';
import _ from 'lodash';

const RenderDynamicTablePieFilter = ({ data, onYearSelect }) => {

  const years = _.map(data, 'year').sort((a, b) => a - b);

  const [selectedYear, setSelectedYear] = useState(years[0]);

  const financialKeys = _.keys(_.get(data, '[0].financialData', {}));

  const rows = _.map(financialKeys, key => {
    const valuesByYear = _.map(years, year => {
      const item = _.find(data, { year });
      return _.get(item, `financialData.${key}.value`, null);
    });

    return {
      key: _.get(data, `[0].financialData.${key}.key`),
      values: valuesByYear
    };
  });

  const definitions = {
    'Unitholders': "The investors who hold units in a REIT.",
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    onYearSelect(year);
  };

  return (
    <table className='tableGrid'>
      <thead>
        <tr>
          <th>Year</th>
          {years.map((year, index) => (
            <th key={index} style={{ cursor: 'pointer' }} >
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "4px" }} onClick={() => handleYearClick(year)}>
                <span>{year}</span>
                <img src={selectedYear === year ? PieIconActive : PieIcon} alt="filter" />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td >
              <div style={{ display: 'flex' }}>
                <div style={{ textAlign: "left" }}>
                  {row.key}
                </div>
                <div style={{ marginTop: '-10px', marginLeft: '5px' }}>
                  {definitions[row.key] && <Tooltip content={definitions[row.key]} />}
                </div>
              </div>
            </td>
            {row.values.map((value, idx) => (
              <td key={idx}>
                {value !== null && value !== undefined ? value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default RenderDynamicTablePieFilter;

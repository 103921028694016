import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import ChevronRightIcon from '../../assets/chevron-right.svg';

import './index.less'

const breadcrumbRoutes = [
  { path: '/', breadcrumb: null },
  { path: '/portfolio-details', breadcrumb: 'Portfolio' },
  { path: '/compare-reits', breadcrumb: 'Comparison REIT' },
  { path: '/compare-adjacent', breadcrumb: 'Compare Properties' },
  { path: '/evaluation', breadcrumb: 'Valuation' },
];

const Breadcrumbs = () => {
  const displayName = 'cmp-breadcrumbs';
  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes);

  return (
    <div className={`${displayName}`}>
      <nav className={`${displayName}_nav`}>
        {location.pathname !== '/' && (
          <span className={`${displayName}_nav-span`}>
            <Link className={`${displayName}_nav-span-links`} to="/">HOME</Link>
            <img className={`${displayName}_nav-span-img`} src={ChevronRightIcon} alt=">" />
          </span>
        )}
        {breadcrumbs
          .filter(({ match }) => match.url !== '/')
          .map(({ match, breadcrumb }, index) => (
            <span className={`${displayName}_nav-links`} key={match.url+"-"+index}>
              <Link className={`${displayName}_nav-span-links`} to={match.url || ""}>{breadcrumb}</Link>
              {index < breadcrumbs.length - 1 &&
                <img className={`${displayName}_nav-span-img`} src={ChevronRightIcon} alt=">" />
              }
            </span>
          ))}
      </nav>
    </div>
  );
};

export default Breadcrumbs;
// import React from 'react';

// const RiskDropdown = ({ label, options, value, onChange }) => {
//   return (
//     <div className="dropdown-section">

//       <label>{label}</label>
//       <select value={value} onChange={onChange}>
//         {options.map(option => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default RiskDropdown;

import React from 'react';
import Select from 'react-select';
import Tooltip from '../../../../../../shared/tool-tip';

const RiskDropdown = ({ label, options, value, onChange }) => {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 10,
    }),
  };

  const handleChange = (selectedOption) => {
    onChange(selectedOption.value);
  };

  // Convert options to the format react-select expects
  const formattedOptions = options.map(option => ({
    value: option.value,
    label: option.label,
  }));

  // Convert the value to the format react-select expects
  const selectedValue = formattedOptions.find(option => option.value === value);

  const definitions = {
    'Wale': "The WALE (or weighted average lease expiry) is the way to measure the average time period in which all/majority of the leases in a property will expire. It is expressed in number of months/years. A higher WALE usually means more stability and less risk of losing tenants in near term.",
    'Vacancy': "The percentage of units or spaces in a rental property that are currently unoccupied.",
    'ESG Compliance': "Adhering to the principles of the environmental, social, and governance (ESG) guidelines established by ESG compliance frameworks and regulatory bodies.",
  };

  return (
    <div className="dropdown-section">
      <div style={{ display: "flex", flexDirection: "row", gap: "0px", position: "relative"}}>
        <div>
          <label>
            {label}
            </label>
            </div>
        <div style={{ left: "-8px", top: "-8px", position: 'relative' }}>
          {definitions[label] && <Tooltip content={definitions[label]} />}
        </div>
      </div>


      <div style={{ width: "59%" }}>
        <Select
          value={selectedValue}
          onChange={handleChange}
          options={formattedOptions}
          styles={customStyles}
        /></div>
    </div>
  );
};

export default RiskDropdown;

import React, { useState } from 'react';
import CompareAdjacentTable from './components/compare-adjacent-table';
import REITMaps from '../reits-maps/index';
import _ from 'lodash';
import './index.less';
import service from '../../services/service';
import DynamicDropdownRow from './components/dynamic-dropdown-row';
import AZUREMaps from '../azure-maps';


const CompareAdjacent = () => {
  const displayName = 'cmp-compare-adjacent';
  const [combinedData, setCombinedData] = useState([]);
  const [mapProps, setmapProps] = useState([]);

  const handleAdjacetChange = async (selectedValue) => {
    setCombinedData([...selectedValue])

    const response = await service.getAdjacentByName(selectedValue);
    if (_.isEmpty(response)) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    const jsonData = await response.data;
    // let recordname = jsonData
    //   .filter((a) => a.name !== selectedValue)
    //   .map((a) => a.name);
    // const centerProperty = jsonData.find((a) => a.name === selectedValue[0]);
    const adjPropertiesLoc = jsonData
      //.filter(a => a.name !== selectedProperty.value)
      .map((a) => ({
        lat: a.latlng.lat,
        lng: a.latlng.lng,
        label: a.latlng.label,
      }));
    let mapPropsData = {
      center: { lat: adjPropertiesLoc[0].lat, lng: adjPropertiesLoc[0].lng },
      adjPropertiesLoc: adjPropertiesLoc,
    };
    setmapProps(mapPropsData);
  };

  return (
    <div className={`${displayName}`}>

      <div className={`${displayName}_map`}>
        <div className='graph_main'>
          <div className='graph_image'>
            <REITMaps props={mapProps}></REITMaps>
            {/* <AZUREMaps props={mapProps}></AZUREMaps> */}
          </div>
          <div className='graph_content'>
            <span>Compare REIT Properties</span>
            <div>
              <DynamicDropdownRow onData={handleAdjacetChange} />
            </div>
          </div>
        </div>
      </div>

      <div className={`${displayName}_main`}>
        <div className={`${displayName}_main_header`}>
          <div className={`${displayName}_main_header-title`}>
            <span className={`${displayName}_main_header-title--name`}>Property Details</span>
          </div>
        </div>
        <div className={`${displayName}_main_body`}>
          {combinedData && <CompareAdjacentTable props={combinedData} />}
        </div>
      </div>
    </div>
  );
};

export default CompareAdjacent;

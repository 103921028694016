import React, { useState, useContext } from 'react';

const EvalutionContext = React.createContext(undefined);

function useEvaluationState() {
    const evaluationState = useContext(EvalutionContext);

    if (evaluationState === undefined) {
        throw new Error('useEvaluation must be within EvaluationContextProvider');
    }

    return evaluationState;
}

const EvaluationContextProvider = ({ children }) => {
    const [evalState, setEvalState] = useState({
        valuationDate: '',
        leaseableArea: 0,
        occupancy: 0,
        avgRental: 0,
        escalation: 5,
        rentalInflow: 0,
        noi: 0,
        noiPercentage: 85,
        columns: [],
        cashFlowData: [],
        errors: {},

        //Calculate Risk Premium
        location: 0,
        tenantProfile: 0,
        wale: 0,
        buildingLife: 0,
        vacancy: 0,
        propertyType: 0,
        esgCompliance: 0,
        riskPremium: 0,

        //Calculate WACC
        waccRange: 0,
        capRate: 0,
        costOfEquity: 0,
        costOfDebt: 0,
        weightOfEquity: 0,
        weightOfDebt: 0,

        //CARE Valuation
        waccDiscountRate: 0,       
        valuationCare: 0,

        //User Valuation
        waccDiscountRateUser: 0,
        valuationUser: 0,
 
    });

    const value = { evalState, setEvalState };

    return (
        <EvalutionContext.Provider value={value}>
            {children}
        </EvalutionContext.Provider>
    )
}

export { EvaluationContextProvider, useEvaluationState }

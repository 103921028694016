import React, { useState } from 'react';
import _ from 'lodash';
import Tooltip from '../../../../../shared/tool-tip';

const RenderDynamicTable = ({ data }) => {

  const years = _.map(data, 'year').sort((a, b) => a - b);

  const financialKeys = _.keys(_.get(data, '[0].financialData', {}));

  const rows = _.map(financialKeys, key => {
    const valuesByYear = _.map(years, year => {
      const item = _.find(data, { year });
      return _.get(item, `financialData.${key}.value`, null);
    });

    return {
      key: _.get(data, `[0].financialData.${key}.key`),
      values: valuesByYear
    };
  });
  console.log(rows);

  const definitions = {
    'Net Operating Income (₹ Cr.)': "Measures an income-producing property's profitability. NOI is gross operating income minus operating expenses.",
    'Revenue from Operations (₹ Cr.)': "The amount of money earns from selling its products or services.",
    'EBITDA (₹ Cr.)': "A measure of core profitability that adds back interest, taxes, depreciation, and amortization to net income.",
    'Debt-to-equity Ratio': "Used to evaluate a company’s financial leverage.",
    'Interest Coverage Ratios': "Used to measure how well a firm can pay the interest due on outstanding debt.",
    'Net Distributable Cash Flows (NDCF) at REIT level (₹ Cr.)': "The cash available for distribution to REIT unit holders after accounting for various expenses and obligations.",
    'Distribution Yield (%)': "The annual income generated by an investment relative to its price.",
    'Gross Rental Collections (₹ Cr.)': "The actual rent amount you collect from those occupied units.",
    '-Dividend (in ₹)': "The distribution of a company's earnings to its shareholders and is determined by the company's board of directors.",
    '-Repayment of Debt (in ₹)': "Paying off your principal debt balance on a loan over a period of time.",
    '-Interest (in ₹)': "The monetary charge for the privilege of borrowing money.",
    'Gross Asset Value (₹ Cr.)': "The current value of all assets held within a property fund.",
    'Net Asset Value (₹ Cr.)': "The current value of all assets held within a property fund, minus any debt attached to the fund.",
    'Debt-to-Unit Capital Ratio': "Measurement of a business's total debt against total capital.",
    'Interest Coverage Ratios (%)': "Determine how easily a company can pay interest on its outstanding debt.",
    'Operating Expenses (₹ Cr.)': "The costs that a company incurs while performing its normal operational activities.",
    "Operating Expenses as % of Value of Assets": "The cost company incurres with respect to value of assests.",
    'Operating Expenses as % of Gross Rentals': "The cost company incurres with respect to the total income.",
    'Occupancy Rate (%)': "The ratio of rented or used space to the total amount of available space.",
    'Re-leasing Spread  (%)': "The difference between the new rent and the prior expiring rent over the same space.",
    'Weighted Average Lease Expiry (WALE)': "The average length of lease for tenents in a property portfolio.",
  };

  const NaDecimal = ["Re-leasing Spread  (%)", "Occupancy Rate (%)", "Re-leasing Spread (%)"];
  const twoDecimal = ["Distribution per units (₹)","Distributions per unit (₹)","-Other Income payment (₹)","-Dividend (₹)","-Repayment of Debt (₹)","-Interest (₹)","-Interest on shareholders loan (₹)"];
  
  return (
    <table className='tableGrid'>
      <thead>
        <tr>
          <th>Year</th>
          {years.map((year, index) => (
            <th key={index}>{year}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td >
              <div style={{ display: 'flex' }}>
                <div style={{ textAlign: "left" }}>
                  {row.key}
                </div>
                <div style={{ marginTop: '-10px', marginLeft: '5px' }}>
                  {definitions[row.key] && <Tooltip content={definitions[row.key]} />}
                </div>
              </div>
            </td>
            {/* 
            {row.values.map((value, idx) => (
              <td key={idx}>
                {value !== null && value !== undefined ? value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}
              </td>
            ))} */}

            {/* {row.values.map((value, idx) => (
              <td key={idx}>
                {NaDecimal.includes(row.key)
                  ? value !== null && value !== undefined && value.value !== " - " && !isNaN(value)
                    ? parseFloat(value).toFixed(0) + "%"
                    : '-'
                  : value !== null && value !== undefined
                    ? value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                    : '-'}
              </td>
            ))} */}
         {row.values.map((value, idx) => {
  // Ensure value is a string and then trim it
              const trimmedValue = typeof value === 'string' ? value.trim() : value;

              return (
                <td key={idx}>
                  {trimmedValue === "NA" ? "NA" : trimmedValue === "" ? "-" : NaDecimal.includes(row.key)
                    ? trimmedValue !== null && trimmedValue !== undefined && trimmedValue !== " - " && !isNaN(trimmedValue)
                      ? parseFloat(trimmedValue).toFixed(0) + "%"
                      : '-'
                    : twoDecimal.includes(row.key)
                      ? trimmedValue !== null && trimmedValue !== undefined && !isNaN(trimmedValue)
                        ? parseFloat(trimmedValue).toFixed(2)
                        : '-'
                      : trimmedValue !== null && trimmedValue !== undefined
                        ? trimmedValue.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                        : '-'}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default RenderDynamicTable;

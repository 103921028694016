import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import { toast } from 'react-toastify';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { token } = useAuth();
  if (!token) {
    toast.warn('Please log in to access this page.', { position: "bottom-right"});
    return <Navigate to="/" />;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
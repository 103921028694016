import React from 'react';
import './index.less';
import ChevronRightIcon from '../../assets/chevron-right.svg';

const Pricepage = () => {
  const displayName = 'cmp-pricepage';

  const priceArray = [
    ['Free', '$0.00 per Month', ['Individual REITS details'], 'Select'],
    ['Bronze', '$20.99 per Month', ['Individual REITS details', 'Compare REITS'], 'Select'],
    ['Silver', '$40.99 per Month', ['Individual REITS details', 'Compare REITS', 'Compare Properties'], 'Select'],
    ['Gold','$80.99 per Month',['Individual REITS details', 'Compare REITS', 'Compare Properties', 'Valuation Page'],'Select'],
  ];

  const handleClick = (item) => {
    //console.log(item);
  };

  return (
    <div className={`${displayName}__container`}>
      {priceArray.map((item, index) => (
        <div className={`${displayName}__column`} key={index}>
          <div className={`${displayName}__column__header`}>
            <div className={`${displayName}__column__header-title`}>{item[0]}</div>
            <div className={`${displayName}__column__header-price`}>
              {item[1] && (
                <>
                  <div className="price-amount">{item[1].split(' ')[0]}</div>
                  <div className="price-period">{item[1].split(' ').slice(1).join(' ')}</div>
                </>
              )}
            </div>
          </div>
          <div className={`${displayName}__column__body`}>
            <ul className={`${displayName}__column__body-list`}>
              {item[2].map((subItem, subIndex) => (
                <li key={subIndex}>
                  <img className={`${displayName}__column__body-list-img`}src={ChevronRightIcon} alt=">"/>
                  {subItem}</li>
              ))}
            </ul>
          </div>
          <div className={`${displayName}__column__footer`}>
            <button 
              className={`${displayName}__column__footer-button`} 
              onClick={() => handleClick(item[3])}
            >
              {item[3]}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Pricepage;


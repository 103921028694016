import React, { useEffect, useState } from 'react';
import "./porfilo-details.less"
import axios from 'axios';

const RealEstateTable = ({ realEstateData }) => {

    const groupedData = realEstateData.reduce((acc, item) => {
        
        const location = item.location.value;

        

        if (!acc[location]) {
            acc[location] = {
                properties: [],
                locationData :{
                    placeName: item.placeName.value,
                    completedAreaMsf: item.completedAreaMsf.value,
                    developmentAreaMsf: item.developmentAreaMsf.value,
                    leasableAreaMsf: item.leasableAreaMsf.value,
                    occupancyRate: item.occupancyRate.value,
                    marketValueInMnRs: item.marketValueInMnRs.value,
                },
            };
        }
        acc[location].properties.push({
            placeName: item.placeName.value,
            completedAreaMsf: item.completedAreaMsf.value || 0,
            developmentAreaMsf: item.developmentAreaMsf.value || 0,
            leasableAreaMsf: item.leasableAreaMsf.value || 0,
            occupancyRate: item.occupancyRate.value || 0,
            marketValueInMnRs: item.marketValueInMnRs.value || 0,
        });

        
        return acc;
    }, {});
    const displayValue = (value) => (
        value == 0 || value == null ? '-' : value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
    )

    return (<>
        <table className='tableGrid'>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th>Completed Area (msf)</th>
                    <th>Under Construction/ Future Area (msf)</th>
                    <th>Leasable Area (msf)</th>
                    <th>Occupancy</th>
                    <th>Market Value (₹ Cr.)</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(groupedData).map(([city, { properties, locationData }], index) => (
                    <React.Fragment key={index}>
                        <tr className="location-header">
                            <td>{city}</td>
                            <td>{displayValue(locationData.completedAreaMsf)}</td>
                            <td>{displayValue(locationData.developmentAreaMsf)}</td>
                            <td>{displayValue(locationData.leasableAreaMsf)}</td>
                            <td>{displayValue(locationData.occupancyRate)}</td>
                            <td>{locationData.marketValueInMnRs ?  locationData.marketValueInMnRs.toLocaleString('en-IN'):'-' }</td>
                        </tr>
                        {properties.map((property, idx) => (
                            property.placeName && (
                            <tr key={idx}>
                                <td>{property.placeName}</td>
                                <td>{property.completedAreaMsf ? typeof property.completedAreaMsf === "number" ? property.completedAreaMsf.toFixed(1): property.completedAreaMsf : '-'}</td>
                                <td>{property.developmentAreaMsf  ? typeof property.developmentAreaMsf === "number" ? property.developmentAreaMsf.toFixed(1): property.developmentAreaMsf  : '-'}</td>
                                <td>{property.leasableAreaMsf ? typeof property.leasableAreaMsf === "number" ? property.leasableAreaMsf.toFixed(1): property.leasableAreaMsf : '-'}</td>
                                <td>{property.occupancyRate ? property.occupancyRate : '-'}</td>
                                <td>{property.marketValueInMnRs ? property.marketValueInMnRs.toLocaleString('en-IN') : '-'}</td>
                            </tr>
                            )
                        ))}
                    </React.Fragment>
                ))}
                <tr><td colSpan="7">*as on March 31, 2024</td></tr>
            </tbody>
        </table>

    </>);
};

export default RealEstateTable;

import React, { useState, useEffect } from 'react';
import Card from './card';
import service from '../../services/service';

import "./index.less"

function CardPanel() {
  const displayName = "cmp-card-panel";
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await service.getCards();
        setData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={`${displayName}`}>
      {data ? data.map((item, index) => (
        <Card key={item.id} data={item} index={index} />
      )) : null}
    </div>
  );
}

export default CardPanel
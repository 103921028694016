import React from 'react';
import './modal.less';

const Modal = ({ children, isOpen, onClose, title }) => {
    if (!isOpen) return null;

    const handleClose = (e) => {
        if (e.target.id === 'modal') onClose();
    }

    return (
        <div className="modal-backdrop" id="modal" onClick={handleClose}>
            <div className="modal-content">
                <div className="modal-header">
                    <span>{title}</span>
                    <button className="close-button" onClick={onClose} >&times;</button>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;
import React, { useEffect, useState } from 'react';
import RiskDropdown from './risk-premium-dropdown/index';

import './index.less';
import { useEvaluationState } from '../../../../../context/evaluation-context';
import Tooltip from '../../../../../shared/tool-tip';

const RiskPremium = () => {

  const { evalState, setEvalState } = useEvaluationState();

  const [location, setLocation] = useState(evalState.location);
  const [tenantProfile, setTenantProfile] = useState(evalState.tenantProfile);
  const [wale, setWale] = useState(evalState.wale);
  const [buildingLife, setBuildingLife] = useState(evalState.buildingLife);
  const [vacancy, setVacancy] = useState(evalState.vacancy);
  const [propertyType, setPropertyType] = useState(evalState.propertyType);
  const [esgCompliance, setEsgCompliance] = useState(evalState.esgCompliance);
  const [riskPremium, setRiskPremium] = useState(evalState.riskPremium);

  const locationOptions = [
    { label: 'Mumbai', value: 0.5 },
    { label: 'Bengaluru', value: 0.5 },
    { label: 'Chennai', value: 0.53 },
    { label: 'Hyderabad', value: 0.751 },
    { label: 'Pune', value: 0.54 },
    { label: 'NCR', value: 0.75 },
    { label: 'Ahmedabad', value: 0.5 },
    { label: 'Amritsar', value: 0.5 },
    { label: 'Bhubaneshwar', value: 0.5 },
    { label: 'Chandighar', value: 0.5 },
    { label: 'Delhi', value: 0.5 },
    { label: 'Gurugram', value: 0.5 },
    { label: 'Indore', value: 0.5 },
    { label: 'Kolkata', value: 0.5 },
    { label: 'Mangaluru', value: 0.5 },
    { label: 'Mysore', value: 0.5 },
    { label: 'Noida', value: 0.5 },
    { label: 'Udaipur', value: 0.5 },
  ];

  const _locations = locationOptions.map(city => ({
    label: city.label,
    value: city.label
  })).sort();

  const tenantProfileOptions = [
    { label: 'Strong', value: 0.25 },
    { label: 'Moderate', value: 0.5 },
    { label: 'Weak', value: 0.75 },
  ];

  const waleOptions = [
    { label: 'More than 5', value: 0.25 },
    { label: 'Between 2-5', value: 0.5 },
    { label: 'Less than 2', value: 0.75 },
  ];

  const buildingLifeOptions = [
    { label: 'Less than 10 years', value: 0.25 },
    { label: 'Between 10-20 years', value: 0.75 },
    { label: 'More than 20 years', value: 1 },
  ];

  const vacancyOptions = [
    { label: 'Less than 10%', value: 0.25 },
    { label: 'Between 10-25%', value: 0.5 },
    { label: 'More than 25%', value: 1 },
  ];

  const propertyTypeOptions = [
    { label: 'Grade A', value: 0.1 },
    { label: 'Grade B', value: 1 },
  ];

  const esgComplianceOptions = [
    { label: 'Good/LEED Platinum or equivalent', value: -0.5 },
    { label: 'Average/LEED Gold or equivalent', value: -0.25 },
    { label: 'Poor/No certification', value: 0 },
  ];


  useEffect(() => {
    const totalRiskPermium = (parseFloat(locationOptions.find(city => city.label === location)?.value) + parseFloat(tenantProfile) + parseFloat(wale) + parseFloat(buildingLife) + parseFloat(vacancy) + parseFloat(propertyType) + parseFloat(esgCompliance)).toFixed(2);
    setRiskPremium(totalRiskPermium)

    setEvalState((prevState) => ({
      ...prevState,
      location: location,
      tenantProfile: tenantProfile,
      wale: wale,
      buildingLife: buildingLife,
      vacancy: vacancy,
      propertyType: propertyType,
      esgCompliance: esgCompliance,
      riskPremium: totalRiskPermium,
    }));
  }, [tenantProfile, wale, buildingLife, vacancy, propertyType, esgCompliance, location, setEvalState]);

  return (
    <div className="risk-premium">
      <div className="risk-premium-header">
        <span>Risk Premium</span>
      </div>
      {/* <div className="dropdown-section">
        <label>Location</label>
        <div>Mumbai</div>
      </div> */}
      <RiskDropdown
        label="Location"
        options={_locations}
        value={location}
        onChange={(e) => setLocation(e)}
      />
      <RiskDropdown
        label="Tenant Profile"
        options={tenantProfileOptions}
        value={tenantProfile}
        onChange={(e) => setTenantProfile(e)}
      />
      <RiskDropdown
        label="WALE"
        options={waleOptions}
        value={wale}
        onChange={(e) => setWale(e)}
      />
      <RiskDropdown
        label="Life of the building"
        options={buildingLifeOptions}
        value={buildingLife}
        onChange={(e) => setBuildingLife(e)}
      />
      <RiskDropdown
        label="Vacancy"
        options={vacancyOptions}
        value={vacancy}
        onChange={(e) => setVacancy(e)}
      />
      <RiskDropdown
        label="Type of property"
        options={propertyTypeOptions}
        value={propertyType}
        onChange={(e) => setPropertyType(e)}
      />
      <RiskDropdown
        label="ESG Compliance"
        options={esgComplianceOptions}
        value={esgCompliance}
        onChange={(e) => setEsgCompliance(e)}
      />
      {/* <div className="dropdown-section">
        <label>Any Other</label>
        <input type="text" />
      </div> */}
      <div className="add-risk-premium">
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative" }}>
          <div> Add: Risk Premium</div>
          <div style={{ top: "-8px", position: 'relative' }}>
            {<Tooltip content={'Risk premium is a measure of excess return that is required by an investor to compensate for an increased level of risk. It is generally included in the discount rate while determining the value of the asset.In other words, a risk premium is the additional return investors expect to get for investing in riskier assets.'} />}
          </div>
        </div>
      </div>
      <div className="risk-premium-value">{riskPremium}%</div>
    </div>
  );
};

export default RiskPremium;
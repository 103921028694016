import React from 'react';
import _ from 'lodash';

const DataTable = ({ props }) => {

    const exchanges = _.map(props, item => _.get(item, 'exchange.value'));
    const financialKeys = _.filter(_.keys(props[0]), key => key !== 'exchange' && key !== 'priceChange');

    const rows = _.map(financialKeys, key => ({
        key: _.get(props[0], `${key}.key`),
        values: _.map(props, item => _.get(item, `${key}.value`))
    }));

    return (
        <table className='tableGrid'>
            <thead>
                <tr>
                    <th>Exchange</th>
                    {exchanges.map((exchange, index) => (
                        <th key={index}>{exchange}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td style={{ textAlign: "left" }}>{row.key}</td>
                        {row.values.map((value, idx) => (
                            <td key={idx}>
                                {value !== null && value !== undefined ? value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DataTable;

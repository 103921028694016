import React, { useState, useEffect } from 'react';
import Multiselect from 'react-select';
import ToggleButton from '../components/toggle-btn';
import service from '../../../services/service';
import _ from 'lodash';
import './index.less';
import DownloadIcon from '../../../assets/download.png';
import FilterIcon from '../../../assets/filter.png';
import CloseIcon from '../../../assets/close.png';
import AddIcon from '../../../assets/add.png';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Tooltip from '../../../shared/tool-tip';

import Brookfieldlogo from '../../../assets/brookfield.svg';
import Embassaylogo from '../../../assets/embassy.svg';
import Mindspacelogo from '../../../assets/mindspace.svg';
import Nexsuslogo from '../../../assets/nexusselecttrust-logo.svg';

const FinancialComparisonTable = ({ props }) => {

  const displayName = 'cmp-financial-compare-table';
  const selectedReits = props

  const [selectedCompanyIds, setSelectedCompanyIds] = useState(selectedReits);
  const [selectedYears, setSelectedYears] = useState(["2024"]);
  const [data, setComapreData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [collapsedCategories, setCollapsedCategories] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(false);
  const [isYearly, setIsYearly] = useState(true);

  const [selectedQuarterOptions, setSelectedQuarterOptions] = useState(null);
  const [quarterOptions, setQuarterOptions] = useState([null]);

  const [selectedYearQuarterOptions, setSelectedYearQuarterOptions] = useState(null);
  const [yearQuarterOptions, setYearQuarterOptions] = useState([null]);

  const styles = { menu: base => ({ ...base, marginTop: 0 }) };

  useEffect(() => {
    if (!selectedReits) {
      setError('REIT ID is not provided');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        //const response = await fetch(`${process.env.API_URL}/reit/compare/data`);
        const response = await service.comapreReit();
        if (_.isEmpty(response)) {
          throw new Error(`HTTP error! status: ${response}`);
        }
        const jsonData = await response.data;
        setComapreData(jsonData);
        const newSelectedOptions = selectedCompanyIds.map(id => {
          const company = jsonData.find(company => company._id === id);
          return {
            value: id,
            label: company.name
          };
        });
        setSelectedOptions(newSelectedOptions);

      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(error.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCompanyIds, selectedReits]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!data) {
    return null;
  }

  const newSelectedOptions = selectedCompanyIds.map(id => {
    const company = data.find(company => company._id === id);
    return {
      value: id,
      label: company.name
    };
  });

  const handleCompanyChange = (selectedOptions) => {
    if (isYearly) {
      if (selectedOptions.length <= 3) {
        // const selectedIds = selectedOptions.map(option => option.value);
        // setSelectedCompanyIds(selectedIds);
        const updatedSelectedCompanyIds = selectedOptions.map(option => option.value);
        setSelectedOptions(selectedOptions);
        setSelectedCompanyIds(updatedSelectedCompanyIds);
      }
      else {
        toast.warn('You cannot select more than 3 REIT.', { position: "bottom-right", });
      }
      if (selectedYears.length === 0) {
        setSelectedCompanyIds([]);
      }
    }
  };

  const handleRemoveCompany = (companyId) => {
    const updatedSelectedOptions = selectedOptions.filter(option => option.value !== companyId);
    const updatedSelectedCompanyIds = updatedSelectedOptions.map(option => option.value);
    setSelectedOptions(updatedSelectedOptions);
    setSelectedCompanyIds(updatedSelectedCompanyIds);
  };

  const toggleCollapse = (category) => {
    setCollapsedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const definitions = {
    'Net Operating Income (₹ Cr.)': "Measures an income-producing property's profitability. NOI is gross operating income minus operating expenses.",
    'Revenue from Operations (₹ Cr.)': "The amount of money earns from selling its products or services.",
    'EBITDA (₹ Cr.)': "A measure of core profitability that adds back interest, taxes, depreciation, and amortization to net income.",
    'Debt-to-equity Ratio': "Used to evaluate a company’s financial leverage.",
    'Interest Coverage Ratios': "Used to measure how well a firm can pay the interest due on outstanding debt.",
    'Net Distributable Cash Flows (NDCF) at REIT level (₹ Cr.)': "The cash available for distribution to REIT unit holders after accounting for various expenses and obligations.",
    'Distribution Yield (%)': "The annual income generated by an investment relative to its price.",
    'Gross Rental Collections (₹ Cr.)': "The actual rent amount you collect from those occupied units.",
    '-Dividend (in ₹)': "The distribution of a company's earnings to its shareholders and is determined by the company's board of directors.",
    '-Repayment of Debt (in ₹)': "Paying off your principal debt balance on a loan over a period of time.",
    '-Interest (in ₹)': "The monetary charge for the privilege of borrowing money.",

    "Basic EPS (in ₹)": "Tells investors how much of a firm's net income was allotted to each share of common stock.",
    "Diluted EPS (in ₹)": "Shows how much profit a company generates for each share of its stock.",
    "Net Profit Margin (%)": "Measures how much profit a company makes as a percentage of its revenue.",
    "Return on Assets (%)": "Represents how much profit a company can generate from its assets.",
    "Current Ratio (X)": "Compares all of a company’s current assets to its current liabilities.",
    "Interest Coverage Ratios (%)": "Used to measure how well a firm can pay the interest due on outstanding debt.",

  };

  const renderCategory3 = (title, categoryName, datakey) => {
    return (
      <>
        <tr onClick={() => toggleCollapse(categoryName)}>
          <td className='subtitle' colSpan={isYearly ? selectedCompanyIds.length * selectedYears.length + 1 : selectedQuarterOptions.length * selectedCompanyIds.length + 1}>
            {title} {collapsedCategories[categoryName] ? '+' : '-'}
          </td>
          <td className='subtitle'></td>
        </tr>
        {collapsedCategories[categoryName] && (
          <React.Fragment>
            {isYearly ?
              Object.keys(data[0].years[0][datakey][categoryName]).map((ratio, index0) => (
                <tr key={`${categoryName}-${ratio}`}>
                  <td className='reitsLastCol'>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                      <div>{data[0].years[0][datakey][categoryName][ratio].key}</div>
                      <div style={{ top: "-8px", position: 'relative' }}>
                        {definitions[data[0].years[0][datakey][categoryName][ratio].key] && <Tooltip content={definitions[data[0].years[0][datakey][categoryName][ratio].key]} />}
                      </div>
                    </div>
                  </td>
                  {selectedCompanyIds.flatMap((_id, index1) =>
                    selectedYears.map((year, index2) => {
                      const company = data.find(c => c._id === _id);
                      const yearData = company ? company.years.find(y => y.year === year) : null;
                      const ratioValue = yearData ? yearData[datakey][categoryName][ratio]?.value !== null ? yearData[datakey][categoryName][ratio]?.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-' : '-';
                      return <td style={{ textAlign: "right" }} key={`${index0}-${index1}-${index2}-${year}-${ratio}`}>{ratioValue}</td>;
                    })
                  )}
                  <td className='reitsLastCol'></td>
                </tr>
              ))
              :
              Object.keys(data[0].years[0][datakey][categoryName]).map((ratio, index) => (
                <tr key={`${categoryName}-${ratio}-${index}`}>
                  <td className='reitsLastCol'>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                      <div>{data[0].years[0][datakey][categoryName][ratio].key}</div>
                      <div style={{ top: "-8px", position: 'relative' }}>
                        {definitions[data[0].years[0][datakey][categoryName][ratio].key] && <Tooltip content={definitions[data[0].years[0][datakey][categoryName][ratio].key]} />}
                      </div>
                    </div>
                  </td>
                  {selectedCompanyIds.flatMap((_id, index3) =>
                    selectedQuarterOptions.map((quarter, index2) => {
                      const company = data.find(c => c._id === _id);
                      const yearData = company ? company.years.find(y => y.year === selectedYearQuarterOptions.value) : null;
                      const _quatre = yearData.quarters.find(x => x.quarter === quarter.value);
                      const ratioValue = yearData ? _quatre[datakey][categoryName][ratio]?.value !== null ? _quatre[datakey][categoryName][ratio]?.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-' : '-';
                      return <td style={{ textAlign: "right" }} key={`${index}-${quarter.value}-${index2}-${datakey}-${categoryName}-${ratio}-${index3}`}>{ratioValue}</td>;
                    })
                  )}
                  <td className='reitsLastCol'></td>
                </tr>
              ))

            }
          </React.Fragment>
        )}
      </>
    );
  };

  const renderCategory2 = (datakey) => {
    return (
      <>
        {/* <tr onClick={() => toggleCollapse(categoryName)}>
                    <td className='subtitle' colSpan={selectedCompanyIds.length * selectedYears.length + 1}>{categoryName}  {collapsedCategories[categoryName] ? '+' : '-'}</td>
                </tr>
                {!collapsedCategories[categoryName] && ( */}
        <React.Fragment>
          {isYearly ?
            Object.keys(data[0].years[0][datakey]).map((ratio, index0) => (
              <tr key={`${datakey}-${ratio}`}>
                <td className='reitsLastCol'>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>{data[0].years[0][datakey][ratio].key}</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions[data[0].years[0][datakey][ratio].key] && <Tooltip content={definitions[data[0].years[0][datakey][ratio].key]} />}
                    </div>
                  </div>
                </td>

                {selectedCompanyIds.flatMap((_id, index1) =>
                  selectedYears.map((year, index2) => {
                    const company = data.find(c => c._id === _id);
                    const yearData = company.years.find(y => y.year === year);
                    const ratioValue = yearData ? yearData[datakey][ratio]?.value !== null ? yearData[datakey][ratio]?.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-' : '-';
                    return <td style={{ textAlign: "right" }} key={`${index0}-${index1}-${index2}-${year}-${ratio}`}>{ratioValue}</td>;
                  })
                )}
                <td className='reitsLastCol'></td>
              </tr>
            ))
            :
            Object.keys(data[0].years[0][datakey]).map((ratio, index) => (
              <tr key={`${index}-${datakey}-${ratio}`}>
                <td className='reitsLastCol'>
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
                    <div>{data[0].years[0].quarters[0][datakey][ratio].key}</div>
                    <div style={{ top: "-8px", position: 'relative' }}>
                      {definitions[data[0].years[0].quarters[0][datakey][ratio].key] && <Tooltip content={definitions[data[0].years[0].quarters[0][datakey][ratio].key]} />}
                    </div>
                  </div>
                </td>

                {selectedCompanyIds.flatMap((_id, index3) =>
                  selectedQuarterOptions.map((quarter, index2) => {
                    const company = data.find(c => c._id === _id);
                    const yearData = company.years.find(y => y.year === selectedYearQuarterOptions.value);
                    const _quatre = yearData.quarters.find(x => x.quarter === quarter.value);
                    const ratioValue = yearData ? _quatre[datakey][ratio]?.value !== null ? _quatre[datakey][ratio]?.value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-' : '-';
                    return <td style={{ textAlign: "right" }} key={`${index}-${quarter.value}-${index2}-${datakey}-${ratio}-${index3}`}>{ratioValue}</td>;
                  })
                )}
                <td className='reitsLastCol'></td>
              </tr>
            ))
          }
        </React.Fragment>
        {/* )} */}
      </>
    );
  };

  const options = data.map(company => ({
    value: company._id,
    label: company.name
  }));

  const yearOptions = [
    { value: '2024', label: 'FY 2024' },
    { value: '2023', label: 'FY 2023' },
    { value: '2022', label: 'FY 2022' },
    { value: '2021', label: 'FY 2021' }
  ];

  const handleYearChange = selectedOptions => {
    if (selectedOptions.length >= 1) {
      if (selectedOptions.length > 3) {
        toast.warn('You can select no more than 3 Years.', { position: "bottom-right", });
      }
      else {
        let _selectedYears = selectedOptions.map(option => option.value);
        const _sortSelectedYears = _.map(_selectedYears).sort((a, b) => a - b);
        setSelectedYears(_sortSelectedYears);
      }
    }
    else {
      toast.warn('At least one year needs to be selected', { position: "bottom-right", });
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleToggle = (value) => {
    setIsYearly(value);
    if (value) {
      //console.log(yearOptions.filter(option => selectedYears.includes(option.value)));
    } else {

      let _data = _.filter(data, (item) => _.includes(selectedCompanyIds, item._id));

      let _quatersFinicalArray = _data.map(x => x.years.map(fy => fy.year));
      let _commonYears = _.intersection(..._quatersFinicalArray)

      const quaterYearOptions = _commonYears.map(year => ({
        value: year,
        label: "FY " + year
      }));

      const maxYear = Math.max(..._commonYears.map(year => parseInt(year, 10)));

      setYearQuarterOptions(quaterYearOptions)
      setSelectedYearQuarterOptions(quaterYearOptions.find(x => x.value == maxYear));

      const quartersArrays = _data.map(company => {
        const yearData = company.years.find(fy => fy.year === maxYear.toString());
        return yearData ? yearData.quarters.map(q => q.quarter) : [];
      });

      let _commonQuarters = _.intersection(...quartersArrays);

      const quaterOptions = _commonQuarters.map(quarter => ({
        value: quarter,
        label: quarter
      }));

      const maxCommonQuarter = _.maxBy(_commonQuarters, quarter => quarter.split(' ')[0].split('Q'));

      setQuarterOptions(quaterOptions);
      setSelectedQuarterOptions([quaterOptions.find(x => x.value == maxCommonQuarter)]);
    }
  };

  const handleQuaterYearChange = (selectedOptions) => {

    let _selectedValue = selectedOptions.value;
    setSelectedYearQuarterOptions(selectedOptions);
    setSelectedQuarterOptions([]);

    let _data = _.filter(data, (item) => _.includes(selectedCompanyIds, item._id));

    const quartersArrays = _data.map(company => {
      const yearData = company.years.find(fy => fy.year === _selectedValue);
      return yearData ? yearData.quarters.map(q => q.quarter) : [];
    });

    let _commonQuarters = _.intersection(...quartersArrays);

    const quaterOptions = _commonQuarters.map(quarter => ({
      value: quarter,
      label: quarter
    }));

    const maxCommonQuarter = _.maxBy(_commonQuarters, quarter => quarter.split(' ')[0].split('Q'));

    setQuarterOptions(quaterOptions);
    setSelectedQuarterOptions([quaterOptions.find(x => x.value == maxCommonQuarter)]);
  };

  const handleQuaterChange = (selectedOptions) => {
    const quarterOrder = ["Q1", "Q2", "Q3", "Q4"];

    if (selectedOptions.length >= 1) {
      if (selectedOptions.length > 4) {
        toast.warn('You can select no more than 4 Quaters.', { position: "bottom-right", });
      }
      else {

        let _selectedYears = selectedOptions.map(option => option.value);

        const _newQuaterOptions = _selectedYears.map(quarter => ({
          value: quarter,
          label: quarter
        }));

        const _sortSelectedYears = _.sortBy(_newQuaterOptions, (item) => {
          const [quarter, year] = item.value.split(' ');
          return quarterOrder.indexOf(quarter) + parseInt(year) * 4;
        });
        setSelectedQuarterOptions(_sortSelectedYears);
      }
    }
    else {
      toast.warn('At least one year needs to be selected', { position: "bottom-right", });
      return
    }
  }

  const getLogo = (companyName) => {
    if (companyName.includes("Embassy")) return Embassaylogo;
    if (companyName.includes("Brookfield")) return Brookfieldlogo;
    if (companyName.includes("Mindspace")) return Mindspacelogo;
    if (companyName.includes("Nexus ")) return Nexsuslogo;
    return null;
  };

  return (
    <div className={`${displayName}`}>
      <div className="titleFilter">
        <h4 style={{ fontFamily: "Gotham-Medium" }}>Compare REITs in detail</h4>
        <div>
          <ToggleButton onToggle={handleToggle} />
        </div>
        <div className="filterMain">
          {isYearly ?
            <div>
              <Multiselect
                isMulti
                options={yearOptions}
                value={yearOptions.filter(option => selectedYears.includes(option.value))}
                onChange={handleYearChange}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={styles}
              />
            </div>
            :
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <Select
                options={yearQuarterOptions}
                value={selectedYearQuarterOptions}
                onChange={handleQuaterYearChange}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={styles}
              />
              <Multiselect
                isMulti
                options={quarterOptions}
                value={selectedQuarterOptions}
                onChange={handleQuaterChange}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={styles}
              />
            </div>
          }
          <span><img src={FilterIcon} alt="Filter" /></span>
          {/* <span><a href="javascript:;"><img src={DownloadIcon} alt="Download" /></a></span> */}
        </div>
      </div>
      <div className='tableHeader'>
        <div >
          {showPopup && (
            <div className="popup">
              <button style={{ background: "transparent", border: "none", textAlign: "right", marginLeft: "93%" }} onClick={togglePopup}> <img src={CloseIcon} alt="Close" /></button>
              <Multiselect
                options={options}
                value={selectedCompanyIds.map(id => options.find(option => option.value === id))}
                onChange={handleCompanyChange}
                isMulti={true}
                className="basic-multi-select"
              />
            </div>
          )}
        </div>
        <table className='tableGrid'>
          <thead style={{ position: "sticky", top: 0, zIndex: "9" }}>
            <tr className='reitsName'>
              <th style={{ background: "white" }}></th>
              {selectedCompanyIds.map(_id => {
                const company = data.find(company => company._id === _id);
                const logo = getLogo(company.name);

                return (
                  <th  key={`${_id}-header`} colSpan={isYearly ? selectedYears.length : selectedQuarterOptions.length}>
                    <img
                      src={logo}
                      style={{ height: "auto", width: "40%", marginBottom: "-30px" }}
                      alt={company.name}
                    />
                    <span className='close'>
                      <button onClick={() => handleRemoveCompany(_id)} style={{ border: "none", background: "transparent" }}>
                        <img src={CloseIcon} alt="Close" />
                      </button>
                    </span>
                    {company.name}
                  </th>
                );
              })}
              <th className='addReits' onClick={togglePopup}>
                <div><img src={AddIcon} alt="Filter" /></div>
                <div><button style={{ marginTop: "8px", border: "none", background: "transparent" }} >Add REIT</button></div>
              </th>
            </tr>
            <tr style={{ fontFamily: "Gotham-Bold" }}>
              <th >(₹ Cr.)</th>
              {isYearly ?
                selectedCompanyIds.flatMap(_id => selectedYears.map(year => (
                  <th key={`${_id}-${year}`} style={{ textAlign: "right", fontWeight: "bolder" }}>{"FY " + year}</th>

                )))
                :
                selectedCompanyIds.flatMap(_id =>
                  selectedQuarterOptions.map(quater => (
                    <th key={`${_id}-${quater.value}`} style={{ textAlign: "right", fontWeight: "bolder" }}> {quater.value}</th>
                  )))
              }
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontFamily: "Gotham-Medium" }}>
              <td className='subMaintitle' colSpan={isYearly ? selectedCompanyIds.length * selectedYears.length + 1 : selectedQuarterOptions.length * selectedCompanyIds.length + 1}>Financial Highlights</td>
              <td className='subMaintitle'></td>
            </tr>
            {renderCategory2('financialData')}
            <tr>
              <td className='subMaintitle' colSpan={isYearly ? selectedCompanyIds.length * selectedYears.length + 1 : selectedQuarterOptions.length * selectedCompanyIds.length + 1} onClick={() => toggleCollapse("ratios1")}>RATIOS &nbsp;{collapsedCategories["ratios1"] ? '+' : '-'}</td>
              <td className='subMaintitle'></td>
            </tr>
            {collapsedCategories["ratios1"] && (
              <>
                {renderCategory3('PER SHARE RATIOS', 'perShareRatios', 'ratios')}
                {renderCategory3('PROFITABILITY RATIOS', 'profitabilityRatios', 'ratios')}
                {renderCategory3('LIQUIDITY RATIOS', 'liquidityRatios', 'ratios')}
                {renderCategory3('COVERAGE RATIOS', 'coverageRatios', 'ratios')}
              </>
            )}
            <tr>
              <td className='subMaintitle' colSpan={isYearly ? selectedCompanyIds.length * selectedYears.length + 1 : selectedQuarterOptions.length * selectedCompanyIds.length + 1}
                onClick={() => toggleCollapse("CashFlows")}>Cash Flows&nbsp;{collapsedCategories["CashFlows"] ? '+' : '-'}</td>
              <td className='subMaintitle'></td>
            </tr>
            {collapsedCategories["CashFlows"] && (
              renderCategory2('cashFlows'))}
            <tr>
              <td className='subMaintitle' colSpan={isYearly ? selectedCompanyIds.length * selectedYears.length + 1 : selectedQuarterOptions.length * selectedCompanyIds.length + 1} onClick={() => toggleCollapse("profitandlossed")}>P&L &nbsp;{collapsedCategories["profitandlossed"] ? '+' : '-'}</td>
              <td className='subMaintitle'></td>
            </tr>
            {collapsedCategories["profitandlossed"] && (
              <>
                {renderCategory3('INCOME', 'incomeRecord', 'pAndL')}
                {renderCategory3('EXPENSES', 'expenses', 'pAndL')}
                {renderCategory3('TAX EXPENSES-CONTINUED OPERATIONS', 'taxExpensesContinuedOperations', 'pAndL')}
                {renderCategory3('EARNINGS PER SHARE', 'earningsPerShare', 'pAndL')}
                {/* {renderCategory3('DIVIDEND AND DIVIDEND PERCENTAGE', 'dividendPercentage', 'pAndL')} */}
              </>
            )}
            <tr>
              <td className='subMaintitle' colSpan={isYearly ? selectedCompanyIds.length * selectedYears.length + 1 : selectedQuarterOptions.length * selectedCompanyIds.length + 1} onClick={() => toggleCollapse("BALANCESHEETed")}>BALANCE SHEET  &nbsp;{collapsedCategories["BALANCESHEETed"] ? '+' : '-'}</td>
              <td className='subMaintitle'></td>
            </tr>
            {collapsedCategories["BALANCESHEETed"] && (
              <>
                {renderCategory3('EQUITIES AND LIABILITIES', 'equitiesAndLiabilities', 'balanceSheet')}
                {renderCategory3('NON-CURRENT LIABILITIES', 'nonCurrentLiabilities', 'balanceSheet')}
                {renderCategory3('CURRENTLIABILITIES', 'currentLiabilities', 'balanceSheet')}
                {renderCategory3('NON-CURRENT ASSETS', 'nonCurrentAssets', 'balanceSheet')}
                {renderCategory3('CURRENT ASSETS', 'currentAssets', 'balanceSheet')}
                {renderCategory3('CONTINGENT LIABILITIES, COMMITMENTS', 'contingentLiabilitiesCommitments', 'balanceSheet')}
                {/* {renderCategory3('BONUS DETAILS', 'bonusDetails', 'balanceSheet')} */}
                {renderCategory3('NON-CURRENT INVESTMENTS', 'nonCurrentInvestmentsDetails', 'balanceSheet')}
                {renderCategory3('CURRENT INVESTMENTS', 'currentInvestmentsDetails', 'balanceSheet')}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FinancialComparisonTable;

export const processData = (data) => {
    const _reitData = (() => {
        if (!data?.years || data.years.length === 0) {
            return {
                ...data,
                years: []
            };
        }

        let maxYear = data.years.reduce((max, element) =>
            element.year > max ? element.year : max, -Infinity);

        return {
            ...data,
            years: data.years.filter(element => element.year === maxYear)
        };
    })();

    const _uniqueLocations = _reitData?.realEstateLocData 
        ? [...new Set(_reitData.realEstateLocData.map(element => element?.location?.value || '').flat())] 
        : [];

    const _nsedata = _reitData?.years?.[0]?.marketPositioning?.find((_d) => _d?.exchange?.key === 'Name' && _d?.exchange?.value === 'NSE') || null;
    const _bsedata = _reitData?.years?.[0]?.marketPositioning?.find((_d) => _d?.exchange?.key === 'Name' && _d?.exchange?.value === 'BSE') || null;

    const _reitYearData = _reitData?.years?.[0] || {};

    const _sponsors = data?.about?.[0]?.sponsors 
        ? [...new Set(data.about[0].sponsors.map((_d) => _d?.name || ''))] 
        : [];

    const _desc = data?.about?.[0]?.description?.value || '';

    const _assectclass = _reitData.about[0]?.primaryassetclass.value;

    return {
        _reitData,
        _uniqueLocations,
        _nsedata,
        _bsedata,
        _reitYearData,
        _sponsors,
        _desc,
        _assectclass
    };
};

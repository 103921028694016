import React, { useState, useEffect } from 'react';
import _, { isNull, isNumber } from 'lodash';

import './index.less';
import service from '../../../../services/service';
import Tooltip from '../../../../shared/tool-tip';
import { format } from 'd3';

const CompareAdjacentTable = (props) => {
  const displayName = 'cmp-adjacent-table';
  const [adjacentData, setAdjacentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const data = Array.isArray(props.props) ? props.props : Array.from(props.props);

  useEffect(() => {
    if (!data || data.length === 0 || !data.some((item) => item)) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await service.compareAdjacent(data.join(','));
        if (_.isEmpty(response)) {
          throw new Error(`HTTP error! status: ${response}`);
        }
        const jsonData = await response.data;
        setAdjacentData(jsonData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(error.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!adjacentData) {
    return null;
  }

  const allKeys = [
    ...new Set(
      adjacentData
        .flatMap(Object.keys)
        .filter(
          (key) =>
            key !== 'isReit' &&
            key !== '_id' &&
            key !== 'PropertyDetails' &&
            key !== 'name' &&
            key !== 'adjacentOF' &&
            key !== 'leasableAreaHistoricalList' &&
            key !== 'address' &&
            key !== 'coordinates' &&
            key !== '__v',
        ),
    ),
  ];

  const getDisplayValue = (property, key) => {
    if (key === 'tenants') {
      return property[key]
        ? property[key].length != 0
          ? property[key].map((tenant) => (
              <li style={{ listStylePosition: 'outside', marginTop: '4px' }}>{tenant.name}</li>
            ))
          : '-'
        : '-';
    }
    if (key === 'isReit') {
      return property[key] ? property[key].toString() : '-';
    }
    if (key === 'occupancy') {
      return property[key] ? isNull(property[key].value) ? '-' : property[key].value + "%" : '-';
    }

    const formatValue = (value, decimals) => isNumber(value) ? (!isNull(value) ? parseFloat(value.toFixed(decimals)).toLocaleString('en-IN') : '-') : value;

    const getFormattedValue = (name, value) => {
      switch (name.replace(/\n/g, ' ')) {
        case 'Leasable Area (in msf/ Hotel keys in nos./ MW)':
          return typeof value === 'string' ? value : formatValue(value, 1);
        case 'Value Of The Property : Cumulative Area (in ₹ psf)':
          return formatValue(value, 1);
        case 'Lease Rentals (in ₹ psf)':
          return value % 1 === 0 ? value : formatValue(value, 1);
        case 'YoY Growth In Lease Rentals  (inplace rents ₹ psf) (%)':
          return value ? value.toFixed(1) + '%' : '-';
        case 'Lease Maturity/Expiry Average Maturity (in yrs)':
          return formatValue(value, 1);
        case 'Value Of The Property : Cumulative Value (₹ Cr.)':
        case 'Valuation (₹ Cr.)':
          return formatValue(value, 0);
        default:
          return !isNaN(value) && !isNaN(parseFloat(value)) ? parseFloat(value).toLocaleString('en-IN') : value;
      }
    };

    if (property[key]) {
      const { key: name, value } = property[key];
      return value === null ? '-' : getFormattedValue(name, value);
    }

    return '-';
  };

  const getDisplayKey = (key) => {
    const property = adjacentData.find((p) => p[key]);
    if (property) {
      if (key === 'tenants') {
        return 'Tenants';
      }
      return property[key].key || key;
    }
    return key;
  };

  const definitions = {
    'Leasable Area (in msf)':
      'Total Leasable Area is the sum of Completed Area, Under Construction Area and Future Development Area.',
    'Occupancy (in %)': 'Occupancy is defined as the ratio of the Occupied Area and the Completed Area.',
    'Lease Rentals (in ₹ psf)': 'Base Rent for a specified month.',
    "Lease Maturity/Expiry : Lease Expiry Details For Each Year In Future (in '000 sf)":
      'Weighted Average Lease Expiry for each year.',
    'Lease Maturity/Expiry Average Maturity (in yrs)':
      'Weighted Average Lease Expiry based on area. Calculated assuming tenants exercise all their renewal options post expiry of their initial commitment period.',
  };

  return (
    <div className={`${displayName}`}>
      <table className={`${displayName}__table`}>
        <thead>
          <tr>
            <th className={`${displayName}__table--th`}>Property</th>
            {adjacentData.map((property, index) => (
              <th className={`${displayName}__table--th`} key={index}>
                {property.name.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allKeys.map((key) => (
            <tr key={key}>
              <td className={`${displayName}__table--td`}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'relative' }}>
                  <div>{getDisplayKey(key)}</div>
                  <div style={{ top: '-8px', position: 'relative' }}>
                    {definitions[getDisplayKey(key).replace(/\n/g, ' ')] && (
                      <Tooltip content={definitions[getDisplayKey(key).replace(/\n/g, ' ')]} />
                    )}
                  </div>
                </div>
              </td>
              {adjacentData.map((property, index) => (
                <td className={`${displayName}__table--td`} key={index}>
                  {getDisplayValue(property, key)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompareAdjacentTable;

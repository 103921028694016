import React, { useState } from 'react';
import { useEvaluationState } from '../../../../context/evaluation-context';
import { updateCalculatedValues } from '../../../../services/cashflow-service';

import './index.less';
import Tooltip from '../../../../shared/tool-tip';

const CashFlowCal = ({ onSwitch, closeModal }) => {
  const displayName = 'cmp-cash-flow';
  const { evalState, setEvalState } = useEvaluationState();

  const [selectedExchange, setSelectedExchange] = useState('cashflow');

  const validateInput = (value, rowIndex, colIndex) => {
    let isValid = true;
    let errorMessage = '';

    if (rowIndex === 0) {
      // Validate Leasable Area
      if (!/^\d+$/.test(value)) {
        isValid = false;
        errorMessage = 'Leasable Area must have value';
      }
    } else if (rowIndex === 1) {
      // Validate Occupancy %
      if (!/^\d+$/.test(value)) {
        isValid = false;
        errorMessage = 'Occupancy must be a percentage';
      }
      else if (value <= 0) {
        isValid = false;
        errorMessage = 'Occupancy Cannot be less than Zero';
      }
      else if (value > 100) {
        isValid = false;
        errorMessage = 'Occupancy Cannot be Greater than 100';
      }
    } else if (rowIndex === 2) {
      // Validate Average Rental
      if (!/^\d+(\.\d+)?$/.test(value)) {
        //  if (!/^\d+$/.test(value)) {
        isValid = false;
        errorMessage = 'Average Rental must be a number';
      }
    } else if (rowIndex === 3) {
      // Validate Escalation %
      if (!/^\d+(\.\d+)?$/.test(value)) {
        //if (!/^\d+$/.test(value)) {
        isValid = false;
        errorMessage = 'Escalation must be a percentage or "-"';
      }
    }

    if (!isValid) {
      setEvalState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [`${rowIndex}-${colIndex}`]: errorMessage,
        }
      }));
    } else {
      setEvalState((prevState) => {
        const newErrors = { ...prevState.errors };
        delete newErrors[`${rowIndex}-${colIndex}`];
        return { ...prevState, errors: newErrors };
      });
    }
  };

  const handleCashFlowCalc = (value, rowIndex, colIndex) => {
    validateInput(value, rowIndex, colIndex);
    const changeData = [...evalState.cashFlowData];
    changeData[rowIndex].values[colIndex] = value;
    const newData = updateCalculatedValues(changeData, evalState.columns, evalState.noiPercentage, true, evalState.capRate);
    setEvalState((prevState) => ({ ...prevState, cashFlowData: newData }));
  }

  const handleNOIPercentageChange = (e) => {
    const newNOIPercentage = e.target.value;
    const updatedData = updateCalculatedValues(evalState.cashFlowData, evalState.columns, newNOIPercentage, false, evalState.capRate);
    setEvalState((prevState) => ({
      ...prevState,
      noiPercentage: newNOIPercentage,
      cashFlowData: updatedData,
    }));
  };

  const handleCapRate = (e) => {
    const _capRate = e.target.value;
    const updatedData = updateCalculatedValues(evalState.cashFlowData, evalState.columns, evalState.noiPercentage, false, _capRate);
    setEvalState((prevState) => ({
      ...prevState,
      capRate: _capRate,
      cashFlowData: updatedData,
    }));
  };

  const definitions = {
    'Leasable Area': "Total Leasable Area is the sum of Completed Area, Under Construction Area and Future Development Area.",
    'Occupancy % (Assumed)': "Occupancy is defined as the ratio of the Occupied Area and the Completed Area.",
    'Average Rental': "Mean Amount rent for a specified month per sqauare feet.",
    'Escalation %': "Increase in rental as per the lease agreement (in percentage terms).",
    'Rental Inflow': "The money earned from leasing out property.",
    'noi': "Net Operating Income (NOI) is a commonly used term to describe the surplus a property is generating after meeting all the operating expenses (like maintenance expenses, cost of utilities, management fees, insurance costs, etc.). It is a method to evaluate the income generated by a property from its regular operations.",
    'caprate': "The capitalization rate or cap rate is one of the metrics to measure the rate of return (in percentage terms) generated on a real estate investment property.",
  };
  return (
    <div className={`${displayName}`}>
      <div className={`${displayName}__nav`}>
        <div className={`${displayName}__navbar`}>
          <button className={`${selectedExchange === 'cashflow' ? `${displayName}__navbar-btn--active` : `${displayName}__navbar-btn`}`} onClick={() => { setSelectedExchange('cashflow'); }} disabled={selectedExchange === 'cashflow'}>
            Cash Flow based on Different Scenarios
          </button>
          <button className={`${selectedExchange === 'waccal' ? `${displayName}__navbar-btn--active` : `${displayName}__navbar-btn`}`} onClick={() => { setSelectedExchange('waccal'); onSwitch() }} disabled={selectedExchange === 'waccal'}>
            WACC Calculation
          </button>
        </div>
      </div>
      <div className={`${displayName}__body`}>
        <div className={`${displayName}__body-label`}>
          {/* <span>
            Based on Users Assumptions
          </span> */}
        </div>
        <div>
          <table className={`${displayName}__body_table`}>
            <thead className={`${displayName}__body_table-thead`}>
              <tr>
                <th>Financial Year</th>
                {evalState.columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody className={`${displayName}__body_table-tbody`}>
              {evalState.cashFlowData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className={`${displayName}__body_table-tbody-label`}>
                    {row.label.startsWith('NOI @') ? (
                      <>
                        <div style={{ display: "flex", flexDirection: "row", gap: "5px", position: "relative", }}>
                          <div>
                            NOI @
                            <input
                              type="number"
                              value={evalState.noiPercentage}
                              onChange={handleNOIPercentageChange}
                              style={{ width: '50px', textAlign: "right" }}
                            />
                            %</div>
                          <div style={{ top: "-4px", position: 'relative' }}>
                            {definitions['noi'] && <Tooltip content={definitions['noi']} />}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "row", gap: "5px", position: "relative", }}>
                        <div>
                          {row.label}
                        </div>
                        <div style={{ top: "-4px", position: 'relative' }}>
                          {definitions[row.label] && <Tooltip content={definitions[row.label]} />}
                        </div>
                      </div>
                    )}
                  </td>
                  {row.values.map((value, colIndex) => (
                    <td key={`${rowIndex}-${colIndex}`} className={evalState.errors[`${rowIndex}-${colIndex}`] ? 'error' : ''}>
                      {(rowIndex === 1 || rowIndex === 2 || (rowIndex === 3 && colIndex !== 0)) && colIndex !== evalState.columns.length - 1 ? (
                        <input
                          type="number"
                          value={value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          onChange={(e) =>
                            handleCashFlowCalc(e.target.value, rowIndex, colIndex)
                          }
                          style={{ textAlign: "right" }}
                        />
                      ) :
                        <span style={{ padding: "10px" }}>
                          {!isNaN(value) && value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      }
                      {evalState.errors[`${rowIndex}-${colIndex}`] && (
                        <div className='error-message'>{evalState.errors[`${rowIndex}-${colIndex}`]}</div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={`cashflow__footer`}>
        <div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", position: "relative", }}>
            <div>
              <label>Cap Rate</label>
              <input type="text" style={{ textAlign: "right" }} onChange={handleCapRate} defaultValue={evalState.capRate.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
            </div>
            <div style={{ top: "-8px", position: 'relative', fontSize: "12px" }}>
              {definitions['caprate'] && <Tooltip content={definitions['caprate']} />}
            </div>
          </div>
        </div>
      </div>
      <div className={`${displayName}__control`} >
        <button className={`${displayName}__control--cancel`} onClick={() => closeModal()}>Cancel</button>
        <button className={`${displayName}__control--continue`} onClick={() => { setSelectedExchange('waccal'); onSwitch() }}>Continue</button>
      </div>
    </div>
  );
};

export default CashFlowCal;

import React, { useState } from 'react';
import { useAuth } from '../context/auth-context';
import LoginForm from '../components/login-form';
import SignupForm from '../components/signup-form';
import Modal from './modal';
import service from '../services/service';

const login = () => {
  const [modalContent, setModalContent] = useState(null);
  const { token, setToken, setUser, logout } = useAuth();

  const openLogin = () => setModalContent('login');
  const openSignup = () => setModalContent('signup');
  const closeModal = () => setModalContent(null);

  const handleLogout = async (e) => {
    e.preventDefault();
    await service.logout();
    logout();
  }

  return (
    <div>
      {token ?
        (<button className="login-close" onClick={handleLogout}>Log out</button>) : (
          <>
            <button className="login-button" onClick={openLogin}>Login</button>
            <Modal isOpen={modalContent !== null} onClose={closeModal}>
              {modalContent === 'login' && <LoginForm onSwitch={openSignup} closemodel={closeModal} />}
              {modalContent === 'signup' && <SignupForm onSwitch={openLogin} closemodel={closeModal} />}
            </Modal>
          </>
        )}
    </div>
  )
}

export default login
import React, { useState, useEffect } from 'react';
import { useSelectedCity, useSelectedProperty } from '../../context/search-context';
import Searchbar from '../../shared/search-bar/index';
import './index.less';
import ValuationTable from './components/valuation-table';
import { EvaluationContextProvider } from '../../context/evaluation-context';

const EvaluationPage = () => {
  const displayName = 'cmp-evaluation';

  const [selectedCity, setSelectedCity] = useSelectedCity();
  const [selectedProperty, setSelectedProperty] = useSelectedProperty();
  const [searchKey, setSearchKey] = useState(Date.now());

  useEffect(() => {
    setSearchKey(Date.now());
  }, [selectedCity, selectedProperty]);

  return (
    <EvaluationContextProvider>
      <div className={`${displayName}`}>
        <div className={`${displayName}__search`}>
          <Searchbar key={searchKey} />
        </div>
        <div className={`${displayName}__valuation`}>
          <ValuationTable />
        </div>
      </div>
    </EvaluationContextProvider>
  );
};

export default EvaluationPage;

import React from 'react';
import HeroBanner from '../hero-banner';
import CardPanel from '../card-panel';
import SearchAction from '../search-action-controls'

import './index.less';

const Dashboard = () => {
  const displaName = 'cmp-dashboard';


  return (
    <div>
      <HeroBanner></HeroBanner>

      <div className={`${displaName}__search`}>
        <SearchAction />
      </div>

      <div className={`${displaName}__container`}>
        <CardPanel />
      </div>

      <div className={`${displaName}__footnote`}>
        <div className={`${displaName}__footnote-tab`}>
          <span className={`${displaName}__footnote-tab--title`}>What are REITs?</span>
          <span className={`${displaName}__footnote-tab--subtitle`}>
          Real Estate Investment Trusts (REITs) are vehicles that own and operateincome generating real estate properties. They enable individual and retail investors to invest in the real estate sector in the form of a financial instrument, without the associated complexities of having to buy or manage any property directly. REITs are required to distribute majority of their income as dividends and are often traded on stock exchanges, offering liquidity similar to stocks.
          </span>
        </div>
        <div className={`${displaName}__footnote-vr`}></div>
        <div className={`${displaName}__footnote-tab`}>
          <span className={`${displaName}__footnote-tab--title`}>Why Invest in REITs?</span>
          <span className={`${displaName}__footnote-tab--subtitle`}>
          Investing in REITs offers diversification, steady income through dividends, liquidity, professional management, and accessibility to real estate with lower capital. They provide an easy way to gain exposure to real estate markets while benefiting from income and potential property appreciation. REITs in India are regulated by the capital markets regulator Securities and Exchange Board of India (SEBI).
            </span>
        </div>
        <div className={`${displaName}__footnote-vr`}></div>
        <div className={`${displaName}__footnote-tab`}>
          <span className={`${displaName}__footnote-tab--title`}>About Us</span>
          <span className={`${displaName}__footnote-tab--subtitle`}>
          CareEdge Analytics is a leading player in providing risk analytics & consulting services to banks and financial institutions (BFSI). With a proven track record of successful implementations across India, Sri Lanka, and Bhutan, CareEdge Analytics demonstrates its expertise and commitment to delivering excellence in the BFSI sector. CareEdge Analytics is a part of CareEdge, a knowledge-based analytical group offering services in Credit Ratings, Analytics, Consulting, and Sustainability.
            </span>
        </div>
      </div>

      <div className={`${displaName}__footer`}>

      </div>
    </div>
  );
};

export default Dashboard;
